
//== Template Main Color
$color-white : #fff;

//== social media colors
$facebook: #3b5999;
$twitter: #55acee;
$instagram: #e4405f;
$youtube: #cd201f;
$pinterest: #bd081c;

/*-----------------
    # Typography
-----------------*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,600&family=Nunito+Sans:opsz,wght@6..12,500;6..12,600&family=Onest:wght@600;700&family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;500;600;700&family=Yantramanav:wght@300;400;500;700;900&display=swap');

//  transition
$transition: all .3s ease-in;
$transition-long: all .5s ease-in;
$box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.06);
$box-shadow2: 0px 10px 30px rgba(0, 33, 71, 0.08);
