
h2.page-blogtitle {
    color: #fff !important;
    font-size: 50px !important;
    line-height: 62px !important;
    margin-bottom: 15px !important;
}
p.link a {
    color: #2866BF;
}
.single-blog-inner.style-2 .details p a {
    color: #2866BF;
}
section.nlssignup {
    position: relative;
    min-height: 80vh;
    background-color: #050233;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .Thankouter {
    width: 800px;
    margin: 0 auto;
    border: 1px solid #7777771f;
    padding: 80px 60px;
    border-radius: 10px;
}
  img.thankuo {
    width: 12%;
    margin-bottom: 30px;
    margin-top: 10px;
}
  section.Thankyou1 {
    padding-top: 100px;
    text-align: center;
    padding-bottom: 100px;
    border-top: 1px solid #7777776b;
}
  section.dispatcher-result .react-tabs__tab:focus:after {
    bottom: -5px;
    content: "";
    height: 5px;
    left: -4px;
    position: absolute;
    right: -4px;
}
  section.nlssignup .container {
    position: relative;
    width: 800px;
    height: 500px;
    background: #fff;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  section.nlssignup .container .user {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }
  p.signup span {
    color: #ef0022;
    font-weight: 500;
    padding-left: 5px;
}
  section.nlssignup .container .user .imgBx {
    position: relative;
    width: 50%;
    height: 100%;
    background: #ef0022;
    transition: 0.5s;
  }
  
  section.nlssignup.container .user .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  section.nlssignup .container .user .formBx {
    position: relative;
    width: 50%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    transition: 0.5s;
  }
  
  section.nlssignup .container .user .formBx form h2 {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
    color: #555;
  }
  
  section.nlssignup .container .user .formBx form input {
    position: relative;
    width: 100%;
    padding: 10px;
    background: #f5f5f5;
    color: #333;
    border: none;
    outline: none;
    box-shadow: none;
    margin: 8px 0;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 300;
  }
  
  section.nlssignup .container .user .formBx form input[type="submit"] {
    max-width: 100px;
    background: #ef0022;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    transition: 0.5s;
  }
  
  section.nlssignup .container .user .formBx form .signup {
    position: relative;
    margin-top: 20px;
    font-size: 12px;
    letter-spacing: 1px;
    color: #555;
    text-transform: uppercase;
    font-weight: 300;
  }
  
  section.nlssignup .container .user .formBx form .signup a {
    font-weight: 600;
    text-decoration: none;
    color: #677eff;
  }
  
  section.nlssignup .container .signupBx {
    pointer-events: none;
  }
  
  section .container.active .signupBx {
    pointer-events: initial;
  }
  
  section.nlssignup .container .signupBx .formBx {
    left: 100%;
  }
  
  section.nlssignup .container.active .signupBx .formBx {
    left: 0;
  }
  
  section.nlssignup .container .signupBx .imgBx {
    left: -100%;
  }
  
  section.nlssignup .container.active .signupBx .imgBx {
    left: 0%;
  }
  
  section.nlssignup .container .signinBx .formBx {
    left: 0%;
  }
  
  section.nlssignup .container.active .signinBx .formBx {
    left: 100%;
  }
  
  section.nlssignup .container .signinBx .imgBx {
    left: 0%;
  }
  
  section.nlssignup .container.active .signinBx .imgBx {
    left: -100%;
  }
  
  @media (max-width: 991px) {
  section.nlssignup .container {
      max-width: 400px;
    }
  
  section.nlssignup .container .imgBx {
      display: none;
    }
  
  section.nlssignup .container .user .formBx {
      width: 100%;
    }
  }
  
section.videobnr .overlay {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-image: linear-gradient(177deg, #000000, transparent);
    background-size: cover;
    height: 90vh;
}
section.multiform .single-input-inner input {
    color: #f8f8f9;
}

section.multiform .single-input-inner textarea {
    width: 100%;
    border-top: none !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 2px solid #fff !important;
    border: 0;
    height: 86px;
    color: #fff;
    border-radius: 0px;
    padding: 17px 5px;
    margin-bottom: -8px;
}
section.Logistics-policy-bnr h2 {
  color: #fff;
}
section.Logistics-policy h3 {
    font-size: 22px;
    font-weight: 700;
    margin-top: 20px;
}
ol li {
    list-style: none;
    margin-bottom: 1rem;
    font-size: 17px;
}
section.Logistics-policy {
    padding-top: 50px;
    padding-bottom: 50px;
}
section.Logistics-policy-bnr {
    padding: 100px 10px;
    text-align: center;
    background-color: #050233;
}
section.insu-bnr {
    position: relative;
    height: 640px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}
section.insu-bnr h2 {
    position: relative;
    color: #fff;
    margin-top: 150px;
    z-index: 1111;
    font-size: 50px;
    line-height: 62px;
    letter-spacing: 1px;
    margin-bottom: 30px;
}
section.dis-bnr h2 {
    position: relative;
    color: #fff;
    margin-top: 152px;
    z-index: 1111;
    font-size: 50px;
    line-height: 62px;
    letter-spacing: 1px;
    margin-bottom: 30px;
}
section.dis-bnr {
    position: relative;
    height: 640px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}
section.Fueladvances-bnr {
    position: relative;
    height: 640px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}
section.Fueladvances-bnr h2 {
    margin-top: 166px;
}
section.Compliance-bnr{
    position: relative;
    height: 640px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}
section.Compliance-bnr .dispatch-content h2 {
    margin-top: 130px;
      z-index: 1111;
    font-size: 50px;
    line-height: 62px;
    letter-spacing: 1px;
    margin-bottom: 30px;
    }

.scroll-to-top {
bottom: 4px !important;
 }
section.myheader a.nav-link {
    color: #000;
}
tr:nth-child(odd) {
    background-color: #8296bd ;
}
section.fulecard-bnr .dispatch-content h2 {
    position: relative;
    color: #fff;
    margin-top: 135px;}
.navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    z-index: 11111;
}
section.myheader li.nav-item.active {
    color: #000158;
    font-weight: 800;
}
section.myheader li.nav-item {
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 800;
    color: #000 !important;
}
.navbar .megamenu{ padding: 1rem; }

/* ============ desktop view ============ */
@media all and (min-width: 992px) {

  .navbar .has-megamenu{position:static!important;}
  .navbar .megamenu{left:0; right:0; width:100%; margin-top:0;  }

}	
/* ============ desktop view .end// ============ */

/* ============ mobile view ============ */
@media(max-width: 991px){
  .navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
    overflow-y: auto;
      max-height: 90vh;
      margin-top:10px;
  }
}
.single-fact-wrap p {
    line-height: 26px;
    margin-bottom: 0;
    color: #fff;
}

.single-fact-wrap h2 {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
    font-weight: 400 !important;
    color: #fff;
}
.single-fact-wrap h4 {
    line-height: 31px;
    margin-bottom: 10px;
    color: #fff;
}
section.Ctainjury {
    margin-top: 0;
}
section.abt-feature h2 {
    color: #0e0f67;
}
.ex3 .card-body p {
    text-align: left;
}
section.mybnr a.btn.btn-base {
    margin-top: 30px;
}
.banner-box ul li {
    padding: 13px;
    border: 1px solid #0e0f67;
    border-radius: 50px;
    margin-right: 12px;
    color: #0e0f67;
    font-weight: 600;
}
section.mybnr::after{
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background: center/cover no-repeat url(../../../../src/nlsimages/back.png);
    z-index: -1;
    right: 0;
    bottom: -412px;
}
.banner-box ul li:hover{
    padding: 13px;
    border: 1px solid #0e0f67;
    border-radius: 50px;
    margin-right: 12px;
    color: #fff;
    font-weight: 600;
    background-color: #0e0f67;
}
p.perfec3t {
    font-weight: 700;
    font-size: 30px;
    line-height: 43px;
    border-bottom: 2px solid #0e0f67;
    padding-bottom: 30px;
    padding-top: 20px;
    margin-bottom: 30px;
    color: #0e0f67;
}
.banner-box ul {
    padding-left: 0px;
    list-style: none;
    display: inline-flex;
}
img.bannertwo {
    top: 20%;
    position: absolute;
    max-width: 950px;
    right: calc(50% - 600px);
    transform: translate(50%, 0);
}
h1.bnr-title {
    font-size: 50px;
    line-height: 65px;
    font-weight: 800;
    font-family: 'Inter', sans-serif;
    text-transform: capitalize;
    padding-top: 170px;
    position: relative;
    color: #000158;
}

h1.bnr-title.whitetitle {
    color: #fff;
    margin-bottom: 30px;
}

section.mybnr {
    position: relative;
    overflow: hidden;
    height: 820px;
    background: linear-gradient(90deg, #d2e5fd80 0%, #b0d3ff00 100%);
}
.mainouter {
    position: relative;
    z-index: 1;
}
section.mainbnner:after {
    background-image: linear-gradient(92deg, #004681, transparent);
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
}
p.main-perfec2t {
    color: #fff;
    font-size: 30px;
    margin-bottom: 30px;
    line-height: 50px;
}
h1.main-title {
    color: #fff;
    margin-bottom: 30px;
}
section.mainbnner {
    background-image: url(../../../../src/nlsimages/mainbanner.jpg);
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding-top: 220px;
    padding-bottom: 220px;
    background-attachment: fixed;
}
section.howworks .stp {
    font-size: 56px;
    margin: 0;
    padding-top: 20px;
    margin-bottom: 20px;
    -webkit-text-stroke: 1px #fff;
    background: transparent;
    color: transparent;
    font-family: "Russo One", sans-serif;
}
section.howworks span.darkblue {
    color: #fff;
    -webkit-text-stroke: 1px #fff;
}
section.howworks h2.How {
    font-weight: 400;
    font-size: 110px;
    line-height: 128px;
    margin: 0;
    color: #fff;
    font-family: "Russo One", sans-serif;
}
.brainstorming {
    position: relative;

}

// section.whynls .why-choose-block:hover .inner-box:hover .brainstorming {
//     display: none;
// }
section.whynls .why-choose-block:hover .inner-box:hover h4 {
    color: #0e0f67;
    transition: all .3s ease-in-out;
}
section.whynls .why-choose-block:hover .inner-box:hover .hoverimg {
      position: relative;
    z-index: 11111;
    display: unset;
}

.hoverimg {
    position: relative;
    z-index: 11111;
    display: none;
}

.why-choose-block .inner-box .icon {
    transition: .5s;
}
section.whynls h2 {
    margin-bottom: 60px;
}
.why-choose-block .inner-box:hover{
 box-shadow: none;
 background: linear-gradient(90deg, #e9e9e9 0%, #ededed 100%);
}
.why-choose-block .inner-box .icon h4 {
    margin-top: 17px;
    font-size: 22px;
    line-height: 32px;
}
section.howworks .facts .smallcontent {
    color: #fff;
}
section.howworks .facts h4 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 15px;
}
.why-choose-block .inner-box {
    padding: 36px 30px 30px;
    background-color: #fff;
    text-align: center;
    height: 220px;
    flex-wrap: wrap;
    margin-bottom: 30px;
    align-items: center;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: all .3s ease-in-out;
    border-radius: 10px;
}
section.whynls {
    padding-top: 60px;
    padding-bottom: 60px;
}
section.Ctainjury {
    margin-top: 0;
    padding-bottom: 45px;
}
.phonecall {
    position: absolute;
    top: 32%;
    left: -50px;
    box-shadow: -16px 12px 43px -9px rgba(64, 64, 64, 0.76);
    border-radius: 10px;
    background: #fff;
}
.phonecall {
    width: 88px;
    padding: 21px;
}
.injured h2 {
    font-size: 46px;
    color: #fff;
    line-height: 72px;
}
.injured span {
    color: #fff;
    font-weight: 300;
}
.believe {
    padding: 45px 50px;
    box-sizing: border-box;
    border-left: none;
    margin-left: -25px;
    background: #fff;
    border: 1px solid;
    z-index: -1111111;
}
p.secure {
    padding-top: 5px;
    padding-bottom: 15px;
}
a.tp-btn-border {
    font-size: 14px;
    text-transform: uppercase;
    line-height: 22px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 0.18em;
    // background: linear-gradient(189deg, #1b1b4c 0%, #0e0f67 100%);
    background: #ef0022;
    padding: 16px 30px;
    border: 1px solid rgba(135, 135, 135, 0.25);
    display: inline-block;
}
a.tp-btn-border span {
    margin-left: 10px;
}
.injured {
    margin-left: 2%;
    padding: 80px 30px 80px 80px;
    background-color: #050233;
    // background: linear-gradient(189deg, #1b1b4c 0%, #0e0f67 100%);
    position: relative;
    box-sizing: border-box;
    margin-top: -24px;
    box-shadow: -16px 12px 43px -9px rgba(64,64,64,0.76);

    border-radius: 10px;
}
.work-block {
    position: relative;
}
.work-block .image-box .image {
    position: relative;
    height: 312px;
    width: 312px;
    border-radius: 50%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border: 3px dashed #ef0022;
    padding: 9px;
    overflow: hidden;
    margin-right: 60px;
}
.work-block .image-box {
    position: relative;
}
.work-block .image-box .image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
}
.work-block .content-box .icon-box .count {
    position: absolute;
    right: 0px;
    top: -10px;
    font-size: 80px;
    line-height: 1em;
    font-weight: 900;
    color: #bfbfbf;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
}
.work-block .content-box .icon-box {
    position: relative;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    margin-bottom: 20px;
}
.work-block .content-box {
    position: relative;
    padding-top: 30px;
    width: 100%;
}
.overlay-anim:after {
    background: rgba(255, 255, 255, 0.3);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    opacity: 1;
    z-index: 9;
    pointer-events: none;
}
h3.title2 {
    font-weight: 800;
}
div#scnd-row{
    position: relative;
    margin-top: 60px;
}
section.Newserivces {
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
}
section.Newserivces  .react-tabs__tab--selected {
    border-color: 0px !important;
    border-radius: unset;
    width: 0%;
    height: 100%;
    border: 0px !important;
    color: #fff !important;
    background-color: #050233;
    // background: linear-gradient(189deg, #1b1b4c 0%, #0e0f67 100%);
     -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    transition: box-shadow .3s ease;
    box-shadow: 10px 6px 40px 0 rgba(26,32,44,.06), 8px 4px 40px 0 rgba(26,32,44,.06);
    z-index: 11;
    width: 362px !important;
    }
p.whenlooking {
    font-size: 22px;
    margin-top: 20px;
    line-height: 33px;
}
section.Newserivces .inner-column {
    position: relative;

}

section.Newserivces  .react-tabs__tab{
    position: relative;
    margin-bottom: 15px;
    display: block;
    padding: 30px 25px 20px 25px;
    padding-left: 60px;
    border: 1px solid #7777770f;
    font-size: 22px;
    line-height:32px;
    color: #000000;
    font-weight: 800;
    width: 100%;
    white-space: nowrap;
    cursor: pointer;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
}
section.Newserivces .react-tabs__tab-list {
    border-bottom: unset;
    margin: 0 0 10px;
    padding: 0;
    display: grid;
    position: relative;
    padding-top: 20px;
}
.work-section .btn-column {
    z-index: 2;
    margin-bottom: 50px;
}
.work-block .inner-box {
    position: relative;
    background-color: #fff;
    padding: 55px 90px 35px;
    padding-right: 75px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    transition: box-shadow .3s ease;
    box-shadow: 10px 6px 40px 0 rgba(26,32,44,.06), 8px 4px 40px 0 rgba(26,32,44,.06);
}

img.dispatchserv {
    margin-bottom: 20px;
}
div#disted {
    margin-top: 60px;
    padding-bottom: 50px;
}
section.multiform #msform .action-button {
    width: 120px;
    background: #14448c;
    font-weight: 500;}
section.multiform .card {
    background: transparent !important;
}
section.multiform #msform .action-button-previous {
    width: 120px;
    background: #fff;
    font-weight: 500;
    border-bottom: 0px !important;
  margin: 10px 5px 10px 10px;
  color: #0e0f67;
  
}
section.multiform #msform fieldset {
    background: transparent;}
#msform fieldset:not(:first-of-type) {
    display: none;
}
section.multiform input {
    padding: 8px 15px 8px 5px !important;
    border-top: none !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 1px solid !important;
    // background-color: transparent !important;
}

    #heading {
        text-transform: uppercase;
        color: #971a1a;
        font-weight: normal
    }

    #msform {
        text-align: center;
        position: relative;
        margin-top: 20px
    }

    #msform fieldset {
        background: white;
        border: 0 none;
        border-radius: 0.5rem;
        box-sizing: border-box;
        width: 100%;
        margin: 0;
        padding-bottom: 20px;
        position: relative
    }

    .form-card {
        text-align: left
    }

    #msform fieldset:not(:first-of-type) {
        display: none
    }

    #msform input,
    #msform textarea {
        padding: 8px 15px 8px 15px;
        border: 1px solid #ccc;
        border-radius: 0px;
        margin-bottom: 25px;
        margin-top: 2px;
        width: 100%;
        box-sizing: border-box;
         color: #fff;
        background-color: transparent;
        font-size: 16px;
      
    }

    #msform input:focus,
    #msform textarea:focus {
        -moz-box-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        border: 1px solid #971a1a;
        outline-width: 0
    }

    #msform .action-button {
        width: 100px;
        background: #971a1a;
        font-weight: bold;
        color: white;
        border: 0 none;
        border-radius: 0px;
        cursor: pointer;
        padding: 10px 5px;
        margin: 10px 0px 10px 5px;
        float: right
    }

    #msform .action-button:hover,
    #msform .action-button:focus {
        background-color: #311B92
    }

    #msform .action-button-previous {
        width: 100px;
        background: #616161;
        font-weight: bold;
        color: white;
        border: 0 none;
        border-radius: 0px;
        cursor: pointer;
        padding: 10px 5px;
        margin: 10px 5px 10px 0px;
        float: right
    }

    #msform .action-button-previous:hover,
    #msform .action-button-previous:focus {
        background-color: #000000
    }

    .card {
        z-index: 0;
        border: none;
        position: relative
    }

    .fs-title {
        font-size: 25px;
        color: #971a1a;
        margin-bottom: 15px;
        font-weight: normal;
        text-align: left
    }

    .purple-text {
        color: #971a1a;
        font-weight: normal
    }

    .steps {
        font-size: 25px;
        color: gray;
        margin-bottom: 10px;
        font-weight: normal;
        text-align: right
    }

    .fieldlabels {
        color: gray;
        text-align: left
    }

    #progressbar {
        margin-bottom: 30px;
        overflow: hidden;
        color: lightgrey
    }

    #progressbar .active {
        color: #971a1a
    }

    #progressbar li {
        list-style-type: none;
        font-size: 15px;
        width: 25%;
        float: left;
        position: relative;
        font-weight: 400
    }

    #progressbar #account:before {
        font-family: FontAwesome;
        content: "\f13e"
    }

    #progressbar #personal:before {
        font-family: FontAwesome;
        content: "\f007"
    }

    #progressbar #payment:before {
        font-family: FontAwesome;
        content: "\f030"
    }

    #progressbar #confirm:before {
        font-family: FontAwesome;
        content: "\f00c"
    }

    #progressbar li:before {
        width: 50px;
        height: 50px;
        line-height: 45px;
        display: block;
        font-size: 20px;
        color: #ffffff;
        background: lightgray;
        border-radius: 50%;
        margin: 0 auto 10px auto;
        padding: 2px
    }

    #progressbar li:after {
        content: '';
        width: 100%;
        height: 2px;
        background: lightgray;
        position: absolute;
        left: 0;
        top: 25px;
        z-index: -1
    }

    #progressbar li.active:before,
    #progressbar li.active:after {
        background: #971a1a
    }

    .progress {
        height: 20px
    }

    .progress-bar {
        background-color: #971a1a
    }

    .fit-image {
        width: 100%;
        object-fit: cover
    }
.multiform {
    padding-top: 60px;
    margin: 0 auto;
    display: block;
    padding-bottom: 15px;
    text-align: center;
    width: 800px;
}

section.Profit-cal .nav-tabs .nav-item {
    margin-bottom: -1px;
    padding: 16px 30px;
    border: 1px solid #eae5e5;
    margin-right: 10px;
    font-weight: 700;
    border-radius: 5px;
}
section.Profit-cal ul.nav.nav-tabs {
    margin-bottom: 30px;
    border-bottom: 0px;
}
section#ddd ::placeholder{color: #fff !important;}
section.Profit-cal label {
    color: #fff;
    padding-bottom: 20px;
    font-size: 20px;
}
img.truck-icon {
    width: 62px;
    margin-right: 15px;
}
section.Profit-cal ::placeholder{color: #fff !important;
    opacity: 0.3;}
section.Profit-cal .nav-link {
    display: block;
    padding: unset;
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: #000;
    text-decoration: none;
    background: 0 0;
    border: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
.result-container h3 {
    color: #fff;
    font-size: 30px;
    font-weight: 300;
}
.overallprofit {
    position: absolute;
    right: 99px;
    top: 24%;
    color: #fff;
}
.overallprofit h3 {
    font-weight: 700;
}
p.gorss {
    color: #ddd98d;
    padding-right: 10px;
    border-right: 1px solid #fff;
}
p.netprofit {
    color: #92dc61;
    padding-left: 10px;
}

.result-container svg {
    position: absolute;
    right: 17px;
    top: 33px;
}

h2.diffent {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
h2.diffent:after {
    background-color: #06155a;
    border-radius: 0;
    content: "";
    display: inline-block;
    height: 3px;
    left: 50%;
    margin: 0;
    position: absolute;
    bottom: -1px;
    vertical-align: bottom;
    width: 50px;
}
p.content-serivce {
    padding-bottom: 30px;
    font-size: 20px;
    margin-top: 10px;
}
section.Updatefaq .card-body {
    padding: 20px 10px 20px 15px;
}
div#dcted {
    margin-top: 30px;
}
ul.certificate-list.focus-state-list-a {
    padding-left: 0px;
    list-style: none;
}
section.serivces-home .right ul li {
    border-top: 1px solid #dddee0;
    align-items: center;
    display: flex;
    height: 100%;
    min-height: 104px;
    padding: 28px 10px;
    transition: all 0.3s;

}
section.Updatefaq :first-of-type .accordion-item {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: 0px !important;
}


section.serivces-home .right ul li .icon {
    height: 60px;
    margin-right: 30px;
    width: 60px;
}
section.serivces-home .right ul li .info {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    display: flex;
    flex-flow: column wrap;
    flex-grow: 1;
}
section.serivces-home {
    padding-top: 60px;
    padding-bottom: 60px;
}
section.serivces-home .right ul li .info .title {
    font-weight: 700;
    color: #161515;
    font-size: 25px;
}
section.serivces-home .right ul li .info .subtitle {
    color: #57595d;
    font-size: 16px;
    font-weight: 400;
    margin-top: 8px;
}
div#counder {
    border-right: 1px solid #777;
    padding-right: 30px;
}
div#fistcounter {
    margin-top: 50px;
}
.counter.box {
    margin-top: 30px;
}
h2.does {
    font-weight: 400;
    font-size: 82px;
    line-height: 104px;
     margin: 0;
    color: #fff;
    font-family: "Russo One", sans-serif;
}
section.abour-counter {
    // border-top: 1px solid rgba(230, 230, 230, 0.8705882353);
    // border-bottom: 1px solid rgba(230, 230, 230, 0.8705882353);
    padding-top: 105px;
    padding-bottom: 85px;
    position: relative;
    overflow: hidden;
    background: #F4F4F4;
    background-color: #050233;
}
li.certificate:hover {
    border: 1px solid #dddee0;
    height: 100%;
    min-height: 104px;
    padding: 28px 10px;
    transition: all 0.3s;
    background: linear-gradient(90deg, #d6d6d6 0%, #e0e0e0 100%);
}
p.Free33 {
    margin-bottom: 0px;
}
h3.templogo2 {
    font-weight: 400;
    padding-top: 10px;
    font-size: 64px;
    line-height: 60px;
    margin: 0;
    -webkit-text-stroke: 1px #000;
    background: transparent;
    color: transparent;
    font-family: "Russo One", sans-serif;
}
a.secondspan {
    font-weight: 500;
    font-size: 20px !important;
    font-family: "Poppins", sans-serif;
    color: #000 !important;
}
h3.nav-info-box-title {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.15px;
    line-height: 22px;
    margin: 0 0 5px;
    padding: 0;
    text-transform: none;
    padding-left: 9px;
    color: #000;
}
img.chatmessage {
    width: 45px;
    margin-right: 12px;
}
.headercnt {
display: flex;
position: relative;
padding-top: 6px;
}
section.fulecard-bnr {
    position: relative;
    height: 640px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
   }
   section.Dispatching-bnr {
    position: relative;
    height: 640px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}
section.Aboutus-bnr {
    background-image: url(../../../../src//nlsimages/disbnr.webp);
    position: relative;
    height: 640px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}
section.abt-insurance {
    padding-top: 80px;
    padding-bottom: 60px;
}
.about-inner-wrap {
    margin-left: 58px;
}
section.abt-insurance h2 {
    margin-bottom: 30px;
}
.reader {
    position: relative;
}
section.Updatefaq .accordion-button {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    color: #161515;
    font-size: 20px;
    background: transparent !important;
    line-height: 32px;
    padding: 20px 10px 20px 15px;
    font-weight: 700;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
p.stp {
    font-size: 46px;
    margin: 0;
    padding-top: 20px;
    margin-bottom: 20px;
    -webkit-text-stroke: 1px #000;
    background: transparent;
    color: transparent;
    font-family: "Russo One", sans-serif;
}
.facts:hover {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color:#d2e5fd;
    box-shadow: 8px 8px 0px 0px #fff;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 0px;
    padding: 30px 40px 30px 40px;
    border-radius: 8px;
    height: 310px;
}
.facts h3 {
    font-size: 25px;
}
h3.templogo {
    font-weight: 400;
    font-size: 64px;
    line-height: 60px;
    margin: 0;
    -webkit-text-stroke: 1px #000;
    background: transparent;
    color: transparent;
    font-family: "Russo One", sans-serif;
}
section.Started {
    padding-top: 60px;
}
img.phoneicon {
    max-width: 33px;
    margin-right: 10px;
}
.callingbnr {
    display: flex;
    border-left: 1px solid #d6d6d6;
    padding-left: 20px;
}
.facts {
    margin-bottom: 35px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #fff;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 0px;
    padding: 30px 40px 30px 40px;
    border-radius: 8px;
    height: 310px;
}
section.fuel-feature {
    padding-top: 60px;
    padding-bottom: 80px;
}
section.Fuelcardbenefits-tab {
    padding-top: 100px;
    padding-bottom: 100px;
}

section.Fuelcardbenefits-tab .react-tabs__tab--selected {
    background: linear-gradient(189deg, #1b1b4c 0%, #0e0f67 100%) !important;
    border-color: #aaa;
    border-radius: 5px 5px 0 0;
    border-radius: 5px;
    border: 2px solid #d2e5fd;
    bottom: auto;
    display: inline-block;
    font-weight: 800;
    color: #fff !important;
    height: 145px;
    padding-top: 5px;
    list-style: none;
    line-height: 32px;
    margin-right: 50px;
    position: relative;
    font-size: 20px;
    text-align: center;
    transition: all 0.3s ease-in-out;
    width: 262px;
    margin-bottom: 32px;
}
section.Fuelcardbenefits-tab .react-tabs__tab {
    background: #fff;
    border: 2px solid #0e0f67;
    border-radius: 5px;
    bottom: auto;
    text-transform: capitalize;
    display: inline-block;
    font-weight: 800;
    color: #0e0f67;
    height: 145px;
    padding-top: 5px;
    list-style: none;
    line-height: 34px;
    margin-right: 50px;
    position: relative;
    font-size: 20px;
    text-align: center;
    transition: all 0.3s ease-in-out;
    width: 262px;
    margin-bottom: 32px;
}
section.Fuelcardbenefits-tab .icon-box-style8 {
    position: relative;
    background: #fff;
    padding: 70px 50px;
    height: 320px;
}
.icon-box-style8 {
    position: relative;
    background: #fff;
    padding: 0px 25px 45px 25px;
    transition: all 0.4s ease;
    box-shadow: 0 -9px 90px -30px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: 1px solid #777;
}
.icon-box-style8:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1);
    transform: scaleX(0);
    transform-origin: bottom left;
    background-color: #050233;
    // background: linear-gradient(189deg, #1b1b4c 0%, #0e0f67 100%);
    background-color: #06155a;
    border-radius: 10px;
}
.icon-box-style8:hover:before {
    transform: scaleX(1);
}

.service.iconbox.icon-box-style8 h6 {
    font-size: 28px;
    font-weight: 800;
    margin-bottom: 22px;
}
.icon-box-style8 .icon-wrap, .icon-box-style8 .title-wrap, .icon-box-style8 .content {
    position: relative;
    z-index: 2;
}
.icon-box-style8:hover .icon-wrap i, .icon-box-style8 h3, .icon-box-style8 span.whitefont, .icon-box-style8:hover p, .icon-box-style8:hover .title-wrap h6, .icon-box-style8:hover .content, .icon-box-style8:hover .button {
    color: #fff;
}
.icon-box-style8:hover .icon-wrap i, .icon-box-style8 h3, .icon-box-style8 span.whitefont, .icon-box-style8:hover p, .icon-box-style8:hover .title-wrap h6, .icon-box-style8:hover .content, .icon-box-style8:hover .button {
    color: #fff;
}
.service .content {
    color: #5b6674;
    margin: 10px auto;
    display: block;
    font-weight: 400;
    font-style: normal;
    font-size: 16px !important;
    line-height: 29px;
    text-transform: none;
    letter-spacing: 0;
}
span.goi {
    background-color: #fff;
    border-bottom-left-radius: 10%;
    border-bottom-right-radius: 10%;
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;
    color: #353535;
    font-size: 20px;
    border: 1px solid #0e0f67;
    font-weight: 700;
    height: 50px;
    left: 0%;
    top: -5px;
    width: 50px;
    line-height: 50px;
    margin-left: -18px;
    position: absolute;
    text-align: center;
}
p.established3 {
    padding-bottom: 30px;
    padding-top: 15px;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.005em;
}
section.Fuelcardbenefits-tab .react-tabs__tab-list {
    border-bottom: 0px;
}
.dispatch-content h2 {
    position: relative;
    color: #fff;
    margin-top: 190px;
    z-index: 1111;
    font-size: 50px;
    line-height: 62px;
    letter-spacing: 1px;
    margin-bottom: 30px;
}
section#ddd {
    // background: linear-gradient(189deg, #1b1b4c 0%, #0e0f67 100%);
    background-color: #050233;
    padding: 10px 80px 50px;
    z-index: 11111;
    position: relative;
}

section#ddd h2 {
    color: #fff;
}
section#ddd p {
    color: #fff;
}
section#ddd section.multiform input {
    padding: 8px 15px 10px 5px !important;
    border-top: none !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 2px solid #fff !important;
}
section.multiform #msform .action-button {
    width: 120px;
    background: linear-gradient(90deg, #d2e5fd 0%, #b0d3ff 100%);
    font-weight: 500;
    border-bottom: 0px !important;
    color: #0e0f67;
}

.copyright-text {
    padding-top: 20px;
}
.work {
    padding-top: 70px;
}
span.darkblue {
    color: #fff;
    -webkit-text-stroke: 1px #fff;
}
section.factury {
    padding-top: 60px;
  position: relative;
  padding-bottom: 110px;
}

.Journey {
    background: #fff;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
section.abt-feature .wrapper-box {
    overflow: hidden;
}
section.abt-feature .outer-box {
    position: relative;
    margin: -16px;
}
.Journey h3 {
    font-size: 25px;
    position: relative;
    margin-bottom: 5px;
    padding-top: 15px;
    position: relative;
}
.Journey::after {
    background-color: #0e0f67;
    border-radius: 0;
    content: "";
    display: inline-block;
    height: 3px;
    left: 10px;
    margin: 0;
    position: absolute;
    bottom: 0;
    vertical-align: bottom;
    width: 50px;
}
h2.Not {
    text-align: center;
    margin-bottom: 10px;
}

section.abt-feature .whay-choose-block {
    border: 1px solid #0000000d;
    padding: 0;
    padding-left: 30px;
    padding-top: 50px;
}
.whay-choose-block .inner-box {
    position: relative;
    margin-bottom: 30px;
    min-height: 120px;
}
.whay-choose-block .icon {
    left: 40px;
    top: 40px;
    color: #fff;
}
.whay-choose-block .icon:before {
    position: absolute;
    content: '';
    left: 24px;
    top: 26px;
    width: 90px;
    height: 90px;
    background-image: url(../../../../src/nlsimages/pattern-18.png);
    border-radius: 50%;
    opacity: 0.4;
}
img.response {
    z-index: 11111;
    position: relative;
    margin-top: 10px;
}
p.established3 {
    font-size: 18px;
    line-height: 28px;
}
.whay-choose-block h4 {
    font-size: 25px !important;
    font-weight: 700;
    line-height: 29px;
    text-transform: none;
    letter-spacing: 0;
    margin: 10px auto;
    font-weight: 800;
    color: #0e0f67;
    text-transform: capitalize;
}
.whay-choose-block .text {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 35px;
    color: #0e0f67;
}
section.howworks {
    padding-top: 100px;
    padding-bottom: 80px;
    position: relative;
    // background: linear-gradient(189deg, #1b1b4c 0%, #0e0f67 100%);
    background-color: #050233;
}
section.dispatcher-result {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
}
section.dispatcher-result .react-tabs__tab--selected {
    font-weight: 700;
    border-radius: 5px 5px 0 0;
    font-size: 18px;
    color: #14448c;
    border-bottom: 2px solid #ef0022 !important;
    padding: 0px 40px 10px 40px;
    border-bottom: 2px solid !important;
    border: none;
}
.table-bordered {
    border: 1px solid #dee4ee;
    color: #333;
    transition: all 0.3s ease-in-out;
}
table.table.table-bordered td {
    padding: 0.7rem 0.8rem;
    text-align: center;
    font-size: 18px;
}
.resultchart {
    border: 10px solid #6e6c6c;
    border-radius: 50%;
    height: 370px;
    margin: 0 auto;
    padding: 53px 20px 25px;
    width: 380px;
}

.myform {
    background: rgba(8, 12, 36, 0.06);
    padding: 30px;
}
.contactdetails {
    margin-bottom: 25px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #000000;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 0px;
    padding: 50px 40px 50px 40px;
    border-radius: 8px;
}
section.contactpage .contactdetails .media {
    border-bottom: 1px solid rgba(8, 12, 36, 0.06);
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.myform .single-input-inner label {
    font-weight: 400;
    font-size: 16px;
    color: #585555;
    margin-bottom: 0px;
    position: absolute;
    top: 0;
    left: 5px;
    height: 52px;
    line-height: 52px;
}
.myform .single-input-inner textarea {
    border: 0;
    height: 100px;
    padding: 17px 40px;
    width: 100%;
    border-radius: 0px;
    font-size: 16px;
    color: #000;
    background: transparent;
    border-bottom: 1px solid;
    margin-bottom: 10px;
}
.myform .single-input-inner input {
    width: 100%;
    height: 50px;
    border: 0;
    border-radius: 0px;
    padding: 0 40px;
    font-size: 16px;
    color: #000;
    background: transparent;
    border-bottom: 1px solid;
    margin-bottom: 7px;
}
footer.footer-area.style-2 .icon {
    text-align: center;
    color: #000;
    font-size: 16px;
    background-color: transparent;
    margin-right: 10px;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
footer.footer-area.style-2 .widget.widget_contact p {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #080c24;
}
p.supply {
    margin-top: 20px;
}
.myform {
    background: rgba(8, 12, 36, 0.06);
    padding: 55px 30px;
    border-radius: 8px;
}
div#form-second-row {
    padding-right: 30px;
}
div#lstbox {
    border-bottom: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
section.contactpage .icon {
    flex: none;
    line-height: 28px;
    border-radius: 5px;
    text-align: center;
    color: #000;
    margin-right: 10px;
    font-size: 20px;
}
.contactdetails h6 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
}
.contactdetails:hover {
    border-style: solid;
    margin-bottom: 25px;
    border-width: 1px 1px 1px 1px;
    border-color: #14448c;
    box-shadow: 5px 5px 0px 0px #14448c;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 0px;
    padding: 50px 40px 50px 40px;
    border-radius: 8px;
}
div#formside {
    padding-right: 50px;
}
section.contactpage .single-input-inner {
    margin-bottom: 12px;
    position: relative;
}
section.contactpage h2 {
    margin-bottom: 24px;
}
section.contactpage {
    padding-top: 80px;
    padding-bottom: 80px;
}
img.smallreffer {
    margin: 0 auto;
    display: block;
    width: 120px;
}
.contact-map iframe {
    width: 100%;
    height: 400px;
    padding: 10px;
}
p.Result-a {
    text-align: center;
    font-size: 15px;
    color: #92909c;
}
h3.totalresult {
    color: #fff;
    background-color: #050233;
    // background: linear-gradient(189deg, #1b1b4c 0%, #0e0f67 100%);
    font-size: 28px;
    width: 200px;
    font-weight: 800;
    margin: 0 auto;
    border-radius: 2px;
    display: block;
    text-align: center;
    padding-top: 10px;
    border-bottom: 1px solid #dee4ee;
    padding-bottom: 10px;
}
.pergross {
    margin: 0 auto;
    display: inline-flex;
    text-align: center;
    float: none;
    width: 100%;
    padding: 0px 40px;
}
h2.How {
    font-weight: 400;
    font-size: 110px;
    line-height: 115px;
    margin: 0;
    -webkit-text-stroke: 1px #000;
    background: transparent;
    color: transparent;
    font-family: "Russo One", sans-serif;
}
hr {
    margin: 1rem 0;
    width: 75%;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: 0.25;
    margin: 0 auto;
}
.left-t {
    margin: 0 auto;
    padding-top: 25px;
}
p.monthly {
    font-size: 30px;
}
p.Result-P {
    text-align: center;
    font-size: 15px;
    color: #92909c;
}
.right-t {
    margin: 0 auto;
    padding-top: 25px;
}
th.n-result {
    padding: 21px 12px 21px;
    line-height: 1.42857143;
    vertical-align: top;
    background-color: #6e6c6c;
    border: none !important;
    color: #fff;
    font-size: 18px;
    font-weight: 800;
    border-top: 0px;
    text-align: center;

}
section.dispatcher-result .react-tabs__tab {
    display: inline-block;
    border-bottom: none;
    bottom: unset;
    position: relative;
    font-size: 20px;
    list-style: none;
    cursor: pointer;
    color: #000;
    padding: 0px 40px 10px 40px;
    position: relative;
}
section.dispatcher-result .react-tabs {
    margin-top: 30px;
    position: relative;
}
section.dispatcher-result ul.react-tabs__tab-list {
    margin-bottom: 40px;
    text-align: left;
    position: relative;
}
.rid-btn {
    float: right;
    margin-top: 10px;
}
.reader {
    position: relative;
}

  .ex3::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

section.trhplescroll {
    padding-top: 65px;
    padding-bottom: 100px;
    position: relative;
    overflow: hidden;
    background: linear-gradient(23deg, #e9e9e942 0%, #ededed 100%);
    // background: #F4F4F4;
    // border-top: 1px solid #e6e6e6de;
    // border-bottom: 1px solid #e6e6e6de;
}
section.abt-feature {

    padding-top: 65px;
    padding-bottom: 65px;
    position: relative;
    overflow: hidden;
    background: linear-gradient(23deg, #e9e9e942 0%, #ededed 100%);
    // background: linear-gradient(90deg, #d2e5fd 0%, #b0d3ff 100%);
}
section.Updatefaq .accordion-item:nth-child(1) .accordion-button {
    border-top: 0px;
}
div#lstcol {
    padding-top: 20px;
    position: relative;
}
.overallprofit h3 {
    font-weight: 700;
}
.overallprofit p {
    color: #92dc61;
    font-size: 20px;
}
section.Profit-cal .result {
    display: flex;
}
section.Profit-cal input.form-control {
    background: transparent;
    border: 0px;
    border-bottom: 1px solid #fff;
    color: #fff;
    border-radius: 0px;
    padding-bottom: 10px;
}
section.Profit-cal .react-tabs__tab-panel--selected {
    display: block;
    background: linear-gradient(90deg,#000a2d 0%,#06155a 100%);
    padding: 53px 80px;
    border-radius: 8px;
}
section.Profit-cal .react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: black;
    border-radius: unset;
    border: 1px solid #14448c !important;
    border-radius: 5px;
}
img.smallpick1 {
    border-radius: 50%;
    margin-bottom: 20px;
    width: 33%;
}
.established-serivce h4 {
    color: #161515;
    font-size: 25px;
    font-weight: 800;
}
section.Profit-cal {
    padding-bottom: 100px;
    padding-top: 80px;
}
.inner-established svg {
    transform: rotate(45deg);
    margin-right: 12px;
}
.read-more a {
    font-size: 18px;
    font-weight: 800;
    color: #000;
}









p.perfec2t {
    color: #fff;
    font-size: 22px;
    margin-bottom: 30px;
    line-height: 33px;
}
p.content-serivce {
    font-size: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
}
img.smallpick1 {
    border-radius: 50%;
    margin-bottom: 20px;
    width: 33%;
}
.newbox {
    padding: 40px 0px 40px 20px;
}
div#new {
    border: 1px solid;
    margin: 0px 0px  30px 0px;
}
div#new:hover {
    border: 1px solid transparent;
    margin: 0px 0px 30px 0px;
    box-shadow: 0px 0px 39px 13px hsl(0deg 0% 57% / 48%);
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
}
div#sidecol {
    padding: 0px;
    position: relative;
}
img.sidedispatch {
    position: absolute;
    bottom: 0;
}
.read-more {
    margin-top: 20px;
}
.newbox h4 {
    margin-bottom: 17px;
    font-size: 30px;
}
.established-serivce:hover {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #14448c;
    box-shadow: 8px 8px 0px 0px #14448c;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 0px;
    padding: 40px 0px 0px 0px;
    border-radius: 0px;
}

.established-serivce {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #000000;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 0px;
    padding: 40px 40px 40px 40px;
    border-radius: 8px;
}
.Journey h4 {
    padding-top: 30px;
}
.downarrow1 {
    position: relative;
}
img.downarrow {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    margin-top: -52px;
    z-index: 2;
    display: inline-block;
}
section.logi-serivces {
    padding-top: 60px;
}
.btn-base {
    color: #fff;
    // background: linear-gradient(90deg,#000a2d 0%,#06155a 100%);
    // background: linear-gradient(189deg, #1b1b4c 0%, #0e0f67 100%);
    line-height: 52px;
    background: #ef0022;
}
section.videobnr {
    position: relative;
    overflow: hidden;
}
.video-background video {
    height: 90vh;
    object-fit: cover;
    width: 100%;
}
.bnerouter {
    position: relative;
    left: 15%;
    top: 25%;
}
h1.video-title {
    color: #fff;
    letter-spacing: 1px;
    font-size: 80px;
    line-height: 95px;
    text-transform: capitalize;
    margin-bottom: 20px;
}

section.contactpage h2 {
    margin-bottom: 24px;
    font-size: 30px;
}
@media (max-width: 480px) and (min-width: 320px){
    img.thankuo {
        width: 25%;
        margin-bottom: 30px;
        margin-top: 10px;
    }
    .Thankouter {
        width: 100%;
        margin: 0 auto;
        border: 1px solid rgba(119, 119, 119, 0.1215686275);
        padding: 40px 20px;
        border-radius: 10px;
    }
    section.Thankyou1 {
        padding-top: 50px;
        text-align: center;
        padding-bottom: 50px;
        border-top: unset;
    }
    .banner-box ul {
        padding-left: 0px;
        list-style: none;
        display: block;
    }
    .multiform {
        margin: 0 auto;
        display: block;
        padding-bottom: 15px;
        text-align: left;
        width: 100%;
    }
    section.insu-bnr h2 {
        margin-top: 70px;
        z-index: 1111;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 1px;
        margin-bottom: 30px;
    }
    section.insu-bnr {
        position: relative;
        height: 470px;
    }
    section.dis-bnr {
        position: relative;
        height: 420px;}

    section.dis-bnr h2 {
        margin-top: 70px;
        z-index: 1111;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 1px;
        margin-bottom: 30px;
    }
    br.huge {
        display: none;
    }
    section.Fueladvances-bnr h2 {
        margin-top: 70px;
        z-index: 1111;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 1px;
        margin-bottom: 30px;
    }
    section.Fueladvances-bnr {
        position: relative;
        height:370px;
    }
    section.Compliance-bnr .dispatch-content h2 {
        margin-top: 60px;
        z-index: 1111;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 1px;
        margin-bottom: 30px;
    }
    section.Compliance-bnr {
        position: relative;
        height: 410px;}
    section.abour-counter {
        padding-top: 40px;
        padding-bottom: 40px;}
    section.Fuelcardbenefits-tab {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    section.fulecard-bnr .dispatch-content h2 {
        margin-top: 60px;
        z-index: 1111;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 1px;
        margin-bottom: 30px;
    }
    .believe {
        padding: 45px 20px;
        margin-left: 0px;
          }
    .phonecall {
        position: absolute;
        top: -37px;
        left: 35%;
        box-shadow: -16px 12px 43px -9px rgba(64, 64, 64, 0.76);
        border-radius: 10px;
        background: #fff;
    }
    .injured {
        margin-left: 0%;
        padding: 80px 30px 50px 80px;
        background-color: #050233;
        position: relative;
        box-sizing: border-box;
        margin-top: 20px;
        box-shadow: -16px 12px 43px -9px rgba(64, 64, 64, 0.76);
        border-radius: 10px;
    }
    .injured h2 {
        color: #fff;
        font-size: 30px;
        line-height: 40px;
    }
    img.bannertwo {
        top: unset;
        position: absolute;
        max-width: 300px;
        right: calc(50% - 230px);
        transform: translate(0%, 0);
    }
    section.mybnr a.btn.btn-base {
        margin-top: 20px;
    }
    .banner-box ul li {
        padding: 13px;
        margin-bottom: 13px;
        text-align: center;
    }
    section.Aboutus-bnr {
      height: 420px;
         }
         p.perfec3t {
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;}
         h1.bnr-title {
          font-size: 36px;
            line-height: 50px;
            padding-top: 55px;
                 }
      
         section.howworks .facts h4 {
            color: #fff;
            font-size: 22px;
                    }  
         section.howworks h2.How {
            font-weight: 400;
            font-size: 50px;
            line-height: 60px; }            
         section.whynls {
            padding-top: 50px;
            padding-bottom: 40px;
        }
         h3.title2 {
            font-weight: 800;
             font-size: 25px;
              }
           .work-block .content-box .icon-box {
              display: none;}
      
           .work-block .image-box .image {
              position: relative;
              height: 150px;
              width: 150px;}
      
          .work-block .inner-box {
              position: relative;
              background-color: #f5f5f5;
              padding: 40px 20px 40px;
              padding-right: 20px;
             display: block;
              }
          section.Newserivces .react-tabs__tab {
             position: relative;
               margin-bottom: 15px;
              display: block;
              padding: 20px 25px 20px 25px;
              padding-left: 30px;
              border: 1px solid rgba(119, 119, 119, 0.0588235294);
              font-size: 18px;
             line-height: 28px;}
      
          section.Newserivces .react-tabs__tab--selected {
              position: relative;
              margin-bottom: 15px;
              display: block;
              font-size:18px;
              line-height: 32px;
              padding: 16px 25px 0px 25px;
              padding-left: 30px;}
      
          section.Newserivces .inner-column {
              position: relative;
              margin-right: 0px;
          
                  }
      
          div#scnd-row {
              position: relative;
              margin-top: 10px;
                  }
      
           p.whenlooking {
               font-size: 16px;
              margin-top: 20px;
              line-height: 26px;
                  }
      
          section.Newserivces {
              position: relative;
              padding-top: 50px;
              padding-bottom: 50px;
                  }
         .media-body p br {
            display: none;
        }
        .media-body p  {
        font-size: 16px;
        }
         .contactdetails {
           padding: 40px 25px 40px 25px;
               }
         section.contactpage {
            padding-top: 50px;
            padding-bottom: 50px;
        }
         div#form-second-row {
            padding-right: 15px;
        }
         span.goi {
            display: none;}
         section.Fuelcardbenefits-tab .react-tabs__tab {
            height: 89px;
            line-height: 23px;
            font-size: 20px;
            width: 100%;
            margin-bottom: 15px;
        }
        section.Fuelcardbenefits-tab .icon-box-style8 {
          padding: 25px;
          height: unset;
        }
        .service.iconbox.icon-box-style8 h6 {
            font-size: 25px;}
         section.fulecard-bnr {
            position: relative;
            height: 370px;}
            // fuelpage
         section.fuel-feature {
            padding-top: 36px;
            padding-bottom: 50px;
            padding-left: 15px;
        }
         .single-fact-wrap {
            position: relative;
            margin-bottom: 0px;
            padding-left: 0px;
            margin-top: 0px;
            border-bottom: 1px solid;
            padding-bottom: 30px;
        }
        .single-fact-wrap h2 {
            font-size: 40px;
            line-height: 50px;
        color: #fff;}

         div#allcount {
            padding: 0px;
        }
         div#counder {
            border-right: unset;
            padding-right: 0px;
        }
         .counter.box svg {
            display: none;}
         h2.does {
            font-weight: 400;
            font-size: 47px;
            line-height: 60px;}
         section.serivces-home .right ul li .info .title {
            font-weight: 800;
            color: #161515;
            font-size: 21px;
        }
         section.serivces-home .right ul li .icon {
            height: 60px;
            margin-right: 20px;
            width: 100px;
        }
         .about-inner-wrap {
            margin-left: 15px;
            margin-top: 50px;
        }
        section.serivces-home {
            padding-top: 30px;
            padding-bottom: 25px;
        }
         section.abt-insurance {
            padding-top: 60px;
            padding-bottom: 30px;
        }

        section.Aboutus-bnr .dispatch-content h2 {
            margin-top: 89px;
            z-index: 1111;
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1px;
            margin-bottom: 30px;
        }
    .bnerouter {
        left: 5%;
        position: relative;
        top: 25%;
    }
    h3.sol {
        font-size: 22px;
    }
    section#ddd section.multiform input{
        font-size: 14px;
    }
    section.multiform #msform {
        text-align: center;
        position: relative;
        margin-top: 10px;
    }
    div#appropriate {
        padding: 0px;
    }
    .multiform {
        padding-top: 0px;
    }
    section#ddd {
      padding: 40px 15px 10px 15px;
        z-index: 11111;
        position: relative;
    }
    section.Profit-cal .nav-tabs .nav-item {
           width: 100%;
        margin-bottom: 10px;
    }
    section.Profit-cal .react-tabs__tab-panel--selected {
        padding: 45px 25px;
        border-radius: 8px;
    }
    section.Profit-cal label {
        color: #fff;
        padding-bottom: 5px;
        font-size: 18px;
    }
    div#lstcol {
        padding-top: 10px;
        position: relative;
    }
    .result-container svg {
        position: absolute;
        right: 8px;
        top: unset;
        bottom: 0;
    }
    .rid-btn {
        display: none;
    }
    section.dispatcher-result .react-tabs__tab {
        display: inline-block;
        border-bottom: 1px solid #77777745;
              padding: 0px 40px 10px 20px;
        position: relative;
        width: 100%;
        font-size: 16px;
        margin-bottom: 10px;
    }
    .resultchart {
        border: 10px solid #6e6c6c ;
        border-radius: 50%;
        height: 300px;
        margin: 0 auto;
        padding: 26px 20px 25px;
        width: 300px;
        margin-top: 30px;
    }
    section.howworks {
        padding-top: 0px;
        padding-bottom: 30px;
    }
    .work svg {
        display: none;
    }
    .facts {
        margin-bottom: 20px;
        padding: 20px 20px 20px 20px;
        height: 285px;
        }
        p.established3 br {
            display: none;
                  }
        p.established3  {
          font-size: 16px;
          line-height: 26px;
        }
        .whay-choose-block .icon:before {
            position: absolute;
            content: "";
            left: -17px;
            top: -25px;
             opacity: 0.3;
        }
        .whay-choose-block .icon {
            position: relative;
            left: 10px;
            top: 0px;
            color: #fff;
        }
        section.Started {
            padding-top: 50px;
        }
        section.factury {
            padding-top: 25px;
            position: relative;
            padding-bottom: 60px;
        }
        section.abt-feature {
           padding-top: 50px;
            padding-bottom: 50px;}
        section.abt-feature .whay-choose-block {
            border: 1px solid rgba(0, 0, 0, 0.0509803922);
            padding: 0;
            padding-left: 20px;
            padding-top: 30px;
        }
        .whay-choose-block .inner-box {
            position: relative;
            padding-left: unset;
            margin-bottom: 30px;
            min-height: unset;
        }
        .facts:hover {
            border-style: solid;
            border-width: 1px 1px 1px 1px;
            border-color: #14448c;
            box-shadow: 5px 5px 0px 0px #14448c;
            transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
            --e-column-margin-right: 20px;
            --e-column-margin-left: 0px;
            padding: 20px 20px 20px 20px;
            border-radius: 8px;
        }
    h2.How {
        font-weight: 400;
        font-size: 70px;
        line-height: 76px;}
    .work {
        padding-top: 40px;
        margin-bottom: 30px;
    }
    h3.totalresult {
      font-size: 23px;
       }
       .pergross {
        margin: 0 auto;
        display: inline-flex;
        text-align: center;
        float: none;
        width: 100%;
        padding: 0px 12px;
    }
    img.smallreffer {
        margin: 0 auto;
        display: block;
        width: 90px;
    }
    section.dispatcher-result ul.react-tabs__tab-list {
        margin-bottom: 30px;
        text-align: left;
        position: relative;
        border-bottom: 0px;
    }
    table.table.table-bordered td {
        padding: 0.7rem 0.8rem;
        text-align: center;
        font-size: 16px;
    }
    section.dispatcher-result {
        padding-top: 50px;
        padding-bottom: 50px;
        position: relative;
    }
    section.Updatefaq .accordion-button {
       font-size: 16px;
        line-height: 26px;
         padding: 20px 10px 20px 15px;
          }
     section.Updatefaq .card-body {
       text-align: left;
        }
    section.trhplescroll {
        padding-top: 50px;
        padding-bottom: 50px;}
    section.Profit-cal .result {
        display: block;
        margin-bottom: 20px;
    }
    p.gorss {
        color: #ddd98d;
        margin-bottom: 0px;
        padding-right: 10px;
        border-right: 0px;
    }
    .overallprofit {
        position: relative;
        right: 0;
        top: 24%;
        color: #fff;
    }
    .result-container h3 {
        color: #fff;
        font-size: 22px;
        font-weight: 300;
    }
    section.Profit-cal .form-group {
        margin-bottom: 20px;
    }
    section.Profit-cal {
        padding-bottom: 50px;
        padding-top: 14px;
    }
    .newbox {
        padding: 30px 0px 30px 10px;
    }
    .newbox h4 {
        margin-bottom: 10px;
        font-size: 26px;
    }
    img.sidedispatch {
        position: absolute;
        bottom: 0;
        width: 53%;
        right: 0;
    }
    img.downarrow {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        margin-top: -23px;
        z-index: 2;
        display: inline-block;
        width: 30%;
    }
    h2 {
        font-size: 30px;
        line-height: 40px;
    }
    p
    {font-size: 16px;
    line-height: 26px;}
    p.content-serivce br {
        display: none;
    }
    section.logi-serivces {
        padding-top: 45px;
    }
    p.content-serivce {
        padding-bottom: 30px;
        font-size: 16px;
        line-height: 26px;
        margin-top: 10px;
    }
    h1.video-title {
        color: #fff;
        font-family: Onest,sans-serif;
        font-size: 28px;
        letter-spacing: 1px;
        line-height: 38px;
    }
    p.perfec2t {
        color: #fff;
        font-size: 20px;
        margin-bottom: 26px;
        line-height: 30px;
    }
}
@media (max-width: 580px) and (min-width: 481px){
    img.thankuo {
        width: 25%;
        margin-bottom: 30px;
        margin-top: 10px;
    }
    .Thankouter {
        width: 100%;
        margin: 0 auto;
        border: 1px solid rgba(119, 119, 119, 0.1215686275);
        padding: 40px 20px;
        border-radius: 10px;
    }
    section.Thankyou1 {
        padding-top: 50px;
        text-align: center;
        padding-bottom: 50px;
        border-top: unset;
    }
    section.Compliance-bnr {
        position: relative;
        height: 335px;}
        br.huge {
            display: none;
        }
        .multiform {
            margin: 0 auto;
            display: block;
            padding-bottom: 15px;
            text-align: left;
            width: 100%;
        }
        section.insu-bnr h2 {
            margin-top: 70px;
            z-index: 1111;
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1px;
            margin-bottom: 30px;
        }
        section.insu-bnr {
            position: relative;
            height: 400px;
        }
        section.dis-bnr {
            position: relative;
            height: 370px;}
            
        section.dis-bnr h2 {
            margin-top: 70px;
            z-index: 1111;
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1px;
            margin-bottom: 30px;
        }
        section.Fueladvances-bnr h2 {
            margin-top: 70px;
            z-index: 1111;
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1px;
            margin-bottom: 30px;
        }
        section.Fueladvances-bnr {
            position: relative;
            height: 410px;
        }
    section.fulecard-bnr .dispatch-content h2 {
        position: relative;
        color: #fff;
        margin-top: 80px;
    }
    section.Compliance-bnr .dispatch-content h2 {
        margin-top: 60px;
        z-index: 1111;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 1px;
        margin-bottom: 30px;
    }
    section.abour-counter {
        padding-top: 40px;
        padding-bottom: 40px;}
    section.abour-counter {
        padding-top: 30px;
        padding-bottom: 30px;}

    section.Fuelcardbenefits-tab {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .phonecall {
        position: absolute;
        top: -37px;
        left: 15%;
        box-shadow: -16px 12px 43px -9px rgba(64, 64, 64, 0.76);
        border-radius: 10px;
        background: #fff;
    }
    .believe {
        padding: 45px 20px;
        margin-left: 0px;
          }
    img.bannertwo {
        display: none;
    }
    .injured {
        margin-left: 0%;
        padding: 80px 30px 40px 80px;
        background-color: #050233;
        position: relative;
        box-sizing: border-box;
        margin-top: 20px;
        box-shadow: -16px 12px 43px -9px rgba(64, 64, 64, 0.76);
        border-radius: 10px;
    }
    .injured h2 {
        color: #fff;
        font-size: 30px;
        line-height: 40px;
    }
    section.mybnr {
     height: 655px;}
    section.mybnr a.btn.btn-base {
        margin-top: 20px;
    }
    .banner-box ul {
        padding-left: 0px;
        list-style: none;
        display: block;
    }
    .banner-box ul li {
        padding: 13px;
        margin-bottom: 13px;
        text-align: center;
    }
    h1.bnr-title {
        font-size: 36px;
          line-height: 50px;
          padding-top: 55px;
               }
               p.perfec3t {
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;}
    .contactdetails {
        padding: 40px 25px 40px 25px;
            }
          
 section.howworks .facts h4 {
    color: #fff;
    font-size: 22px;
            }            
    section.howworks h2.How {
       font-weight: 400;
       font-size: 50px;
       line-height: 60px; }   

    section.whynls {
     padding-top: 50px;
     padding-bottom: 40px;
            }
     h3.title2 {
      font-weight: 800;
       font-size: 25px;
        }
     .work-block .content-box .icon-box {
        display: none;}

     .work-block .image-box .image {
        position: relative;
        height: 150px;
        width: 150px;}

    .work-block .inner-box {
        position: relative;
        background-color: #f5f5f5;
        padding: 40px 20px 40px;
        padding-right: 20px;
       display: block;
        }
    section.Newserivces .react-tabs__tab {
       position: relative;
         margin-bottom: 15px;
        display: block;
        padding: 20px 25px 20px 25px;
        padding-left: 30px;
        border: 1px solid rgba(119, 119, 119, 0.0588235294);
        font-size: 18px;
       line-height: 28px;}

    section.Newserivces .react-tabs__tab--selected {
        position: relative;
        margin-bottom: 15px;
        display: block;
        font-size:18px;
        line-height: 32px;
        padding: 16px 25px 0px 25px;
        padding-left: 30px;}

    section.Newserivces .inner-column {
        position: relative;
        margin-right: 0px;
               }

    div#scnd-row {
        position: relative;
        margin-top: 10px;
            }

     p.whenlooking {
         font-size: 16px;
        margin-top: 20px;
        line-height: 26px;
            }

    section.Newserivces {
        position: relative;
        padding-top: 50px;
        padding-bottom: 50px;
            }

            .media-body p br {
                display: none;
            }
            .media-body p  {
                font-size: 16px;
                }
    span.goi {
        display: none;}
     section.Fuelcardbenefits-tab .react-tabs__tab {
        height: 89px;
        line-height: 23px;
        font-size: 20px;
        width: 100%;
        margin-bottom: 15px;
    }
    section.contactpage {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    div#form-second-row {
        padding-right: 15px;
    }
    section.Fuelcardbenefits-tab .icon-box-style8 {
      padding: 25px;
      height: unset;
    }
    .service.iconbox.icon-box-style8 h6 {
        font-size: 25px;}

     section.fulecard-bnr {
        position: relative;
        height: 380px;}

    section.Aboutus-bnr {
        height: 420px;
           }
           section.fuel-feature {
            padding-top: 36px;
            padding-bottom: 50px;
            padding-left: 15px;
        }
           .single-fact-wrap h2 {
            font-size: 40px;
            line-height: 50px;}
           .single-fact-wrap {
            position: relative;
            margin-bottom: 0px;
            padding-left: 0px;
            margin-top: 30px;
            border-bottom: 1px solid;
            padding-bottom: 30px;
        }
           div#allcount {
            padding: 0px;
        }
           div#counder {
            border-right: unset;
            padding-right: 0px;
        }
           .counter.box svg {
            display: none;}
           h2.does {
            font-weight: 400;
            font-size: 58px;
            line-height: 68px;}
           section.serivces-home .right ul li .info .title {
            font-weight: 800;
            color: #161515;
            font-size: 21px;
        }
           section.serivces-home .right ul li .icon {
            height: 60px;
            margin-right: 20px;
            width: 100px;
        }
           .about-inner-wrap {
            margin-left: 15px;
            margin-top: 50px;
        }   
        section.serivces-home {
            padding-top: 30px;
            padding-bottom: 25px;
        }    
           section.abt-insurance {
            padding-top: 60px;
            padding-bottom: 30px;
        }
           .dispatch-content h2 {
            position: relative;
            color: #fff;
            margin-top: 124px;
            z-index: 1111;
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1px;
            margin-bottom: 30px;
        }
    .bnerouter {
        left: 5%;
        position: relative;
        top: 25%;
    }
    section#ddd section.multiform input{
        font-size: 14px;
    }
    section.multiform #msform {
        text-align: center;
        position: relative;
        margin-top: 10px;
    }
    div#appropriate {
        padding: 0px;
    }
    .multiform {
        padding-top: 0px;
    }
    section#ddd {
        padding: 40px 15px 10px 15px;
        z-index: 11111;
        position: relative;
    }
    section.Profit-cal .nav-tabs .nav-item {
           width: 100%;
        margin-bottom: 10px;
    }
    section.Profit-cal .react-tabs__tab-panel--selected {
        padding: 45px 25px;
        border-radius: 8px;
    }
    section.Profit-cal label {
        color: #fff;
        padding-bottom: 5px;
        font-size: 18px;
    }
    div#lstcol {
        padding-top: 10px;
        position: relative;
    }
    .result-container svg {
        position: absolute;
        right: 8px;
        top: unset;
        bottom: 0;
    }
    .rid-btn {
        display: none;
    }
    section.dispatcher-result .react-tabs__tab {
        display: inline-block;
        border-bottom: 1px solid #77777745;
              padding: 0px 40px 10px 20px;
        position: relative;
        width: 100%;
        font-size: 16px;
        margin-bottom: 10px;
    }
    .resultchart {
        border: 10px solid #717070;
        border-radius: 50%;
        height: 300px;
        margin: 0 auto;
        padding: 26px 20px 25px;
        width: 300px;
    }
    section.howworks {
        padding-top: 0px;
        padding-bottom: 30px;
    }
    .work svg {
        display: none;
    }
    .facts {
        margin-bottom: 20px;
        padding: 20px 20px 20px 20px;
        height: 230px;
        }
        p.established3 br {
            display: none;
                  }
        p.established3  {
          font-size: 16px;
          line-height: 26px;
        }
        .whay-choose-block .icon:before {
            position: absolute;
            content: "";
            left: -17px;
            top: -25px;
             opacity: 0.3;
        }
        .whay-choose-block .icon {
            position: relative;
            left: 10px;
            top: 0px;
            color: #fff;
        }
        section.Started {
            padding-top: 50px;
        }
        section.factury {
            padding-top: 25px;
            position: relative;
            padding-bottom: 60px;
        }
        section.abt-feature {
           padding-top: 50px;
            padding-bottom: 50px;}
        section.abt-feature .whay-choose-block {
            border: 1px solid rgba(0, 0, 0, 0.0509803922);
            padding: 0;
            padding-left: 20px;
            padding-top: 30px;
        }
        .whay-choose-block .inner-box {
            position: relative;
            padding-left: unset;
            margin-bottom: 30px;
            min-height: unset;
        }
        .facts:hover {
            border-style: solid;
            border-width: 1px 1px 1px 1px;
            border-color: #14448c;
            box-shadow: 5px 5px 0px 0px #14448c;
            transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
            --e-column-margin-right: 20px;
            --e-column-margin-left: 0px;
            padding: 20px 20px 20px 20px;
            border-radius: 8px;
        }
    h2.How {
        font-weight: 400;
        font-size: 70px;
        line-height: 76px;}
    .work {
        padding-top: 40px;
        margin-bottom: 30px;
    }
    h3.totalresult {
      font-size: 23px;
       }
       .pergross {
        margin: 0 auto;
        display: inline-flex;
        text-align: center;
        float: none;
        width: 100%;
        padding: 0px 12px;
    }
    img.smallreffer {
        margin: 0 auto;
        display: block;
        width: 90px;
    }
    section.dispatcher-result ul.react-tabs__tab-list {
        margin-bottom: 30px;
        text-align: left;
        position: relative;
        border-bottom: 0px;
    }
    table.table.table-bordered td {
        padding: 0.7rem 0.8rem;
        text-align: center;
        font-size: 16px;
    }
    section.dispatcher-result {
        padding-top: 50px;
        padding-bottom: 50px;
        position: relative;
    }
    section.Updatefaq .accordion-button {
       font-size: 16px;
        line-height: 26px;
         padding: 20px 10px 20px 15px;
          }
     section.Updatefaq .card-body {
       text-align: left;
        }
    section.trhplescroll {
        padding-top: 50px;
        padding-bottom: 50px;}
    section.Profit-cal .result {
        display: block;
        margin-bottom: 20px;
    }
    p.gorss {
        color: #ddd98d;
        margin-bottom: 0px;
        padding-right: 10px;
        border-right: 0px;
    }
    .overallprofit {
        position: relative;
        right: 0;
        top: 24%;
        color: #fff;
    }
    .result-container h3 {
        color: #fff;
        font-size: 22px;
        font-weight: 300;
    }
    section.Profit-cal .form-group {
        margin-bottom: 20px;
    }
    section.Profit-cal {
        padding-bottom: 50px;
        padding-top: 14px;
    }
    .newbox {
        padding: 30px 0px 30px 10px;
    }
    .newbox h4 {
        margin-bottom: 10px;
        font-size: 26px;
    }
    img.sidedispatch {
        position: absolute;
        bottom: 0;
        width: 53%;
        right: 0;
    }
    img.downarrow {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        margin-top: -32px;
        z-index: 2;
        display: inline-block;
        width: 30%;
    }
    h2 {
        font-size: 30px;
        line-height: 40px;
    }
    p
    {font-size: 16px;
    line-height: 26px;}
    p.content-serivce br {
        display: none;
    }
    section.logi-serivces {
        padding-top: 45px;
    }
    p.content-serivce {
        padding-bottom: 30px;
        font-size: 16px;
        line-height: 26px;
        margin-top: 10px;
    }
    h1.video-title {
        color: #fff;
        font-family: Onest,sans-serif;
        font-size: 28px;
        letter-spacing: 1px;
        line-height: 38px;
    }
    h3.sol {
        font-size: 22px;
    }
    p.perfec2t {
        color: #fff;
        font-size: 20px;
        margin-bottom: 26px;
        line-height: 30px;
    }
}
@media (max-width: 767px) and (min-width: 581px){
    img.thankuo {
        width: 25%;
        margin-bottom: 30px;
        margin-top: 10px;
    }
    section.Thankyou1 {
        padding-top: 50px;
        text-align: center;
        padding-bottom: 50px;
        border-top: unset;
    }
    .Thankouter {
        width: 100%;
        margin: 0 auto;
        border: 1px solid rgba(119, 119, 119, 0.1215686275);
        padding: 40px 20px;
        border-radius: 10px;
    }
    section.Compliance-bnr {
        position: relative;
        height: 370px;}
        br.huge {
            display: none;
        }
        section.insu-bnr h2 {
            margin-top: 70px;
            z-index: 1111;
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1px;
            margin-bottom: 30px;
        }
        section.insu-bnr {
            position: relative;
            height: 410px;
        }
        section.dis-bnr {
            position: relative;
            height: 370px;}
            
        section.dis-bnr h2 {
            margin-top: 70px;
            z-index: 1111;
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1px;
            margin-bottom: 30px;
        }
        section.Fueladvances-bnr h2 {
            margin-top: 70px;
            z-index: 1111;
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1px;
            margin-bottom: 30px;
        }
        section.Fueladvances-bnr {
            position: relative;
            height: 410px;
        }
        section.Compliance-bnr .dispatch-content h2 {
            margin-top: 60px;
            z-index: 1111;
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1px;
            margin-bottom: 30px;
        }
        section.Compliance-bnr .dispatch-content h2 {
            margin-top: 64px;
            font-size: 30px;
            line-height: 43px;
        }
    section.abour-counter {
        padding-top: 30px;
        padding-bottom: 30px;}

    section.fulecard-bnr .dispatch-content h2 {
        position: relative;
        color: #fff;
        margin-top: 80px;
    }
    section.Fuelcardbenefits-tab {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    section.mybnr a.btn.btn-base {
        margin-top: 20px;
    }
    .injured {
        margin-left: 0px;
        padding: 60px 30px 40px 80px;
       margin-top: 0px;}
       .phonecall {
        position: absolute;
        top: -46px;
        left: 10%;
        }
        .believe {
           margin-left: 0px;}

    img.bannertwo {
        top: unset;
        position: absolute;
        max-width: 450px;
        right: calc(50% - 300px);
        transform: translate(5%, 0);
    }
    .injured h2 {
        color: #fff;
        font-size: 45px;
        line-height: 66px;
    }
    .banner-box ul {
        padding-left: 0px;
        list-style: none;
        display: inline-flex;
    }
    .banner-box ul li {
        padding: 13px;
        margin-bottom: 13px;
        text-align: center;
    }
    p.perfec3t {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;}
    h1.bnr-title {
        font-size: 36px;
          line-height: 50px;
          padding-top: 55px;
               }
    section.howworks .facts h4 {
        color: #fff;
        font-size: 22px;
                }   
                     
        section.howworks h2.How {
           font-weight: 400;
           font-size: 50px;
           line-height: 60px; }   
    
        section.whynls {
         padding-top: 50px;
         padding-bottom: 40px;
                }
         h3.title2 {
          font-weight: 800;
           font-size: 25px;
            }
         .work-block .content-box .icon-box {
            display: none;}
    
         .work-block .image-box .image {
            position: relative;
            height: 150px;
            width: 150px;}
    
        .work-block .inner-box {
            position: relative;
            background-color: #f5f5f5;
            padding: 40px 20px 40px;
            padding-right: 20px;
           display: block;
            }
        section.Newserivces .react-tabs__tab {
           position: relative;
             margin-bottom: 15px;
            display: block;
            padding: 20px 25px 20px 25px;
            padding-left: 30px;
            border: 1px solid rgba(119, 119, 119, 0.0588235294);
            font-size: 18px;
           line-height: 28px;}
    
        section.Newserivces .react-tabs__tab--selected {
            position: relative;
            margin-bottom: 15px;
            display: block;
            font-size:18px;
            line-height: 32px;
            padding: 16px 25px 0px 25px;
            padding-left: 30px;}
    
        section.Newserivces .inner-column {
            position: relative;
            margin-right: 0px;
                          }
    
        div#scnd-row {
            position: relative;
            margin-top: 10px;
                }
    
         p.whenlooking {
             font-size: 16px;
            margin-top: 20px;
            line-height: 26px;
                }
    
        section.Newserivces {
            position: relative;
            padding-top: 50px;
            padding-bottom: 50px;
                }
    .contactdetails {
        padding: 40px 25px 40px 25px;
            }
            .media-body p br {
                display: none;
            }
            .media-body p  {
                font-size: 16px;
                }
     section.Fuelcardbenefits-tab .react-tabs__tab {
        height: 89px;
        line-height: 23px;
        font-size: 20px;
        width: 100%;
        margin-bottom: 15px;
    }
    section.contactpage {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    div#form-second-row {
        padding-right: 15px;
    }
    section.Fuelcardbenefits-tab .icon-box-style8 {
      padding: 25px;
      height: unset;
    }
    .service.iconbox.icon-box-style8 h6 {
        font-size: 25px;}

     section.fulecard-bnr {
        position: relative;
        height: 345px;}
    section.Aboutus-bnr {
        height: 420px;
           }
           section.fuel-feature {
            padding-top: 36px;
            padding-bottom: 50px;
            padding-left: 15px;
        }
           .single-fact-wrap h2 {
            font-size: 40px;
            line-height: 50px;}
           .single-fact-wrap {
            position: relative;
            margin-bottom: 0px;
            padding-left: 0px;
            margin-top: 30px;
            border-bottom: 1px solid;
            padding-bottom: 30px;
        }
           div#allcount {
            padding: 0px;
        }
           div#counder {
            border-right: unset;
            padding-right: 0px;
        }
           .counter.box svg {
            display: none;}
           h2.does {
            font-weight: 400;
            font-size: 58px;
            line-height: 68px;}
           section.serivces-home .right ul li .icon {
            height: 60px;
            margin-right: 20px;
            width: 100px;
        }
        section.serivces-home .right ul li .info .title {
            font-weight: 800;
            color: #161515;
            font-size: 21px;
        }
           .about-inner-wrap {
            margin-left: 15px;
            margin-top: 50px;
        }
        section.serivces-home {
            padding-top: 30px;
            padding-bottom: 25px;
        }
           section.abt-insurance {
            padding-top: 60px;
            padding-bottom: 30px;
        }
           .dispatch-content h2 {
            position: relative;
            color: #fff;
            margin-top: 124px;
            z-index: 1111;
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1px;
            margin-bottom: 30px;
        }
    .bnerouter {
        left: 5%;
        position: relative;
        top: 35%;
    }
    section#ddd section.multiform input{
        font-size: 14px;
    }
    section.multiform #msform {
        text-align: center;
        position: relative;
        margin-top: 10px;
    }
    div#appropriate {
        padding: 0px;
    }
    .multiform {
        padding-top: 0px;
    }
    section#ddd {
        padding: 40px 15px 10px 15px;
        z-index: 11111;
        position: relative;
    }
    section.Profit-cal .nav-tabs .nav-item {
           width: 100%;
        margin-bottom: 10px;
    }
    section.Profit-cal .react-tabs__tab-panel--selected {
        padding: 45px 25px;
        border-radius: 8px;
    }
    section.Profit-cal label {
        color: #fff;
        padding-bottom: 15px;
        font-size: 18px;
    }
    div#lstcol {
        padding-top: 10px;
        position: relative;
    }
    .result-container svg {
        position: absolute;
        right: 8px;
        top: unset;
        bottom: 0;
    }
    .rid-btn {
        display: none;
    }
    section.dispatcher-result .react-tabs__tab {
        display: inline-block;
        border-bottom: 1px solid #77777745;
              padding: 0px 40px 10px 20px;
        position: relative;
        width: 100%;
        font-size: 16px;
        margin-bottom: 10px;
    }
    .resultchart {
        border: 10px solid #717070;
        border-radius: 50%;
        height: 300px;
        margin: 0 auto;
        padding: 26px 20px 25px;
        width: 300px;
    }
    section.howworks {
        padding-top: 0px;
        padding-bottom: 30px;
    }
    .work svg {
        display: none;
    }
    .facts {
        margin-bottom: 20px;
        padding: 20px 20px 20px 20px;
        height: 225px;
        }
        p.established3 br {
            display: none;
                  }
        p.established3  {
          font-size: 16px;
          line-height: 26px;
        }
        .whay-choose-block .icon:before {
            position: absolute;
            content: "";
            left: -17px;
            top: -25px;
             opacity: 0.3;
        }
        .whay-choose-block .icon {
            position: relative;
            left: 10px;
            top: 0px;
            color: #fff;
        }
        section.Started {
            padding-top: 50px;
        }
        section.factury {
            padding-top: 25px;
            position: relative;
            padding-bottom: 60px;
        }
        section.abt-feature {
           padding-top: 50px;
            padding-bottom: 50px;}
        section.abt-feature .whay-choose-block {
            border: 1px solid rgba(0, 0, 0, 0.0509803922);
            padding: 0;
            padding-left: 20px;
            padding-top: 30px;
        }
        .whay-choose-block .inner-box {
            position: relative;
            padding-left: unset;
            margin-bottom: 30px;
            min-height: unset;
        }
        .facts:hover {
            border-style: solid;
            border-width: 1px 1px 1px 1px;
            border-color: #14448c;
            box-shadow: 5px 5px 0px 0px #14448c;
            transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
            --e-column-margin-right: 20px;
            --e-column-margin-left: 0px;
            padding: 20px 20px 20px 20px;
            border-radius: 8px;
        }
    h2.How {
        font-weight: 400;
        font-size: 70px;
        line-height: 76px;}
    .work {
        padding-top: 40px;
        margin-bottom: 30px;
    }
    h3.totalresult {
      font-size: 23px;
       }
       .pergross {
        margin: 0 auto;
        display: inline-flex;
        text-align: center;
        float: none;
        width: 100%;
        padding: 0px 12px;
    }
    img.smallreffer {
        margin: 0 auto;
        display: block;
        width: 90px;
    }
    section.dispatcher-result ul.react-tabs__tab-list {
        margin-bottom: 30px;
        text-align: left;
        position: relative;
        border-bottom: 0px;
    }
    table.table.table-bordered td {
        padding: 0.7rem 0.8rem;
        text-align: center;
        font-size: 16px;
    }
    section.dispatcher-result {
        padding-top: 50px;
        padding-bottom: 50px;
        position: relative;
    }
    section.Updatefaq .accordion-button {
       font-size: 16px;
        line-height: 26px;
         padding: 20px 10px 20px 15px;
          }
     section.Updatefaq .card-body {
       text-align: left;
        }
    section.trhplescroll {
        padding-top: 50px;
        padding-bottom: 50px;}
    section.Profit-cal .result {
        display: block;
        margin-bottom: 20px;
    }
    p.gorss {
        color: #ddd98d;
        margin-bottom: 0px;
        padding-right: 10px;
        border-right: 0px;
    }
    .overallprofit {
        position: relative;
        right: 0;
        top: 24%;
        color: #fff;
    }
    .result-container h3 {
        color: #fff;
        font-size: 22px;
        font-weight: 300;
    }
    section.Profit-cal .form-group {
        margin-bottom: 20px;
    }
    section.Profit-cal {
        padding-bottom: 50px;
        padding-top: 14px;
    }
    .newbox {
        padding: 30px 50px 30px 10px;
    }
    .newbox h4 {
        margin-bottom: 10px;
        font-size: 26px;
    }
    img.sidedispatch {
        position: absolute;
        bottom: 0;
        width: 30%;
        right: 0;
    }
    img.downarrow {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        margin-top: -32px;
        z-index: 2;
        display: inline-block;
        width: 30%;
    }
    h2 {
        font-size: 30px;
        line-height: 40px;
    }
    p
    {font-size: 16px;
    line-height: 26px;}
    p.content-serivce br {
        display: none;
    }
    section.logi-serivces {
        padding-top: 45px;
    }
    p.content-serivce {
        padding-bottom: 30px;
        font-size: 16px;
        line-height: 26px;
        margin-top: 10px;
    }
    h1.video-title {
        color: #fff;
        font-family: Onest, sans-serif;
        font-size: 36px;
        letter-spacing: 1px;
        line-height: 46px;
    }
    h3.sol {
        font-size: 22px;
    }
    p.perfec2t {
        color: #fff;
        font-size: 20px;
        margin-bottom: 26px;
        line-height: 30px;
    }
}
@media (max-width: 991px) and (min-width: 768px){
    .Thankouter {
    width: 100%;}

    section.Thankyou1 {
        padding-top: 60px;
        text-align: center;
        padding-bottom: 60px;
        border-top: unset;
    }
    h1.bnr-title {
        font-size: 45px;
        line-height: 65px;
       padding-top: 70px;
          }
          section.insu-bnr h2 {
            font-size: 40px;
            line-height: 50px;
            margin-top: 80px;
        }  
        section.insu-bnr {
            position: relative;
            height: 470px;}

          section.dis-bnr h2 {
            position: relative;
            color: #fff;
            margin-top: 94px;
            font-size: 40px;
            line-height: 50px;}
          section.dis-bnr {
            position: relative;
            height: 440px;
        }
          section.Fuelcardbenefits-tab .icon-box-style8 {
            position: relative;
            background: #fff;
            padding: 70px 50px;
            height: unset;
        }
          section.Fueladvances-bnr {
            position: relative;
            height: 397px;
        }
        section.Fueladvances-bnr h2 {
            margin-top: 80px;
        }
          br.save {
            display: none;
        }
          section.Compliance-bnr {
            position: relative;
            height: 397px;}
          section.Compliance-bnr .dispatch-content h2 {
            margin-top: 64px;
            font-size: 40px;
            line-height: 54px;
        }
          .injured h2 {
            font-size: 33px;
            color: #fff;
            line-height: 54px;
            padding-top: 50px;
            padding-bottom: 60px;
        }
        section.Started {
            padding-top: 20px;
        }
          br.huge {
            display: none;
        }
          section.fulecard-bnr .dispatch-content h2 {
            margin-top: 94px;
        }
          .phonecall {
            width: 66px;
            padding: 10px;
            left: -27px;
        }
          .injured {
            margin-left: 2%;
            padding: 35px 30px 35px 60px;}
          .believe {
            padding: 26px 20px;}
            img.bannertwo {
                top: unset;
                position: absolute;
                max-width: 412px;
                right: calc(50% - 446px);
                transform: translate(0, 0);
                bottom: 3%;
            }

    .footer-area .widget {
        position: relative;
        z-index: 2;
        margin-bottom: 40px;
    }
    section.mybnr {
        position: relative;
        overflow: hidden;
        height: 765px;}
    section.Newserivces .react-tabs__tab--selected{
      padding: 30px 25px 14px 25px;
        padding-left: 30px;
              font-size: 20px;
        line-height: 22px;
    }
   
    section.dispatcher-result {
        padding-top: 60px;
        padding-bottom: 60px;
        position: relative;
    }
    section.howworks .facts h4 {
        color: #fff;
        font-size: 22px;
    }
    section.howworks h2.How {
        font-weight: 400;
          font-size: 62px;
    line-height: 68px;}
    p.whenlooking br {
        display: none;
    }
    span.count {
        display: none;
    }
    .work-block .inner-box {
        position: relative;
        background-color: #f5f5f5;
        padding: 35px 50px 35px;
        padding-right: 50px;
        display: flex;
    }
    section.Newserivces .react-tabs__tab{
        padding: 30px 25px 14px 25px;
        padding-left: 30px;
              font-size: 20px;
        line-height: 22px;
        width: 256px !important;

    }
    section.Newserivces .inner-column {
        position: relative;
          }
    figure.image.overlay-anim {
        display: none;
    }
    section.Newserivces {
        position: relative;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .media-body p br {
        display: none;
    }
    .media-body p  {
        font-size: 16px;
        }
    .contactdetails {
        padding: 30px 20px 30px 20px;
    }
    .dispatch-content h2 {
       margin-top: 75px;
        font-size: 40px;
        line-height: 50px;
         }
         section.Fuelcardbenefits-tab .react-tabs__tab {
            height: 80px;
            line-height: 21px;
            font-size: 20px;
            width: 89%;
            margin-bottom: 25px;
        }
        span.goi {
         top: 12px;}

         section.fulecard-bnr {
            position: relative;
            height: 540px;}
         .single-fact-wrap h2 {
            font-size: 40px;
            line-height: 40px;
            margin-bottom: 10px;
            font-weight: 400 !important;
        }
        .bluefuelcard {
            margin-bottom: 40px;
        }
         .single-fact-wrap {
            position: relative;
            margin-bottom: 20px;
            padding-left: 15px;
        }
        section.multiform #msform {
            text-align: center;
            position: relative;
            margin-top: 0px;
        }
        div#appropr {
            padding-left: 0px;
        }
        section.fuel-feature {
            padding-top: 60px;
            padding-bottom: 60px;
        }
        section#ddd .multiform {
            padding-top: 40px;
            margin: 0 auto;
            display: block;
            padding-bottom: 15px;
            text-align: center;
            width: 100%;
        }
        section#ddd {
          padding: 10px 25px;}

        section.abour-counter .single-fact-wrap {
            padding-left: 0px;
            margin-bottom: 50px;
        }
        section.abour-counter {
             padding-top: 60px;
            padding-bottom: 30px;}

        .counter.box {
            margin-bottom: 16px;
        }
        div#counder {
            border-right: 0px;
            padding-right: 15px;
        }
        .counter.box svg {
            display: none;}
        h2.does {
            font-weight: 400;
            font-size: 74px;
            line-height: 90px;
        }
         section.serivces-home {
            padding-top: 50px;
            padding-bottom: 30px;
        }
        section.serivces-home .right ul li .info .title {
            font-weight: 800;
            color: #161515;
            font-size: 22px;
        }
        section.serivces-home .right ul li .icon {
            height: 60px;
            margin-right: 15px;
            width: 100px;
        }
         section.abt-insurance {
            padding-top: 60px;
            padding-bottom: 40px;
        }
         .about-inner-wrap {
            margin-left: 15px;
            margin-top: 0px;
        }
   section.Aboutus-bnr {
     height: 400px;}

    h1.video-title {
        color: #fff;
        letter-spacing: 1px;
        font-size: 55px;
        line-height: 70px;
        font-family: "Onest", sans-serif;
    }
    
    .whay-choose-block .inner-box {
        position: relative;
        padding-left: 20px;
        margin-bottom: 30px;
        min-height: 120px;
    }
    .whay-choose-block .icon:before {
        position: absolute;
        content: "";
        left: 10px;
        top: 10px;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        opacity: 0.3;
    }
    img.Freightoring {
        margin-top: 30px;
    }
    section.factury {
        padding-top: 5px;
        position: relative;
        padding-bottom: 90px;
    }
    .whay-choose-block h4 {
    font-size: 22px !important;
    font-weight: 700;
    line-height: 32px;}

    .whay-choose-block .icon {
        padding-left: 18px;
    }
    section.abt-feature .whay-choose-block {
        border: 1px solid rgba(0, 0, 0, 0.0509803922);
        padding: 0;
        padding-left: 0px;
        padding-top: 30px;
    }
    h2.How {
      font-size: 83px;
     line-height: 94px;}

    .work {
        padding-top: 0;
        padding-bottom: 40px;
    }
    .resultchart {
        border: unset;
        border-radius: 50%;
        height: unset;
        margin: 0 auto;
        padding: 33px 10px 20px 10px;
        width: unset;
    }
    p.established3 br {
        display: none;
    }
    .facts h4 {
        font-size: 23px;
    }
    section.howworks {
      padding-top: 50px;}

  .work svg {
    position: absolute;
    top: 6px;
    right: 0;
    height: 80px;
    transform: rotate(135deg);
}
    .pergross {
       padding: 0px 0px;
    }
    table.table.table-bordered td {
     font-size: 16px;
    }
    h3.totalresult {
    font-size: 26px;}

    section.Updatefaq .accordion-button {
        font-size: 18px;
       line-height: 28px;}
    .result-container h3 {
        color: #fff;
        font-size: 24px;
        font-weight: 300;
    }
    section.Profit-cal label {
        color: #fff;
        padding-bottom: 20px;
        font-size: 16px;
    }
section.Profit-cal .react-tabs__tab-panel--selected {
  padding: 53px 15px;
}
    section.Profit-cal .nav-tabs .nav-item {
        margin-bottom: -1px;
        padding: 16px 10px;
        border: 1px solid #eae5e5;
        margin-right: 5px;
        font-weight: 700;
        border-radius: 5px;
    }
    section.Profit-cal {
        padding-bottom: 66px;
        padding-top: 35px;
    }
    p.content-serivce br {
        display: none;
    }
    h2 {
        font-size: 38px;
        line-height: 48px;
    }
    .bnerouter {
        position: relative;
        left: 4%;
        top: 25%;
    }
}
@media (max-width: 1023px) and (min-width: 992px){

    .Thankouter {
        width: 100%;}
    
        section.Thankyou1 {
            padding-top: 60px;
            text-align: center;
            padding-bottom: 60px;
            border-top: unset;
        }
    section.Compliance-bnr .dispatch-content h2 {
        margin-top: 100px;
        font-size: 46px;
        line-height: 60px;
    }
    section.insu-bnr h2 {
        position: relative;
        color: #fff;
        margin-top: 70px;}
section.insu-bnr {
    position: relative;
    height: 500px;
}
    section.dis-bnr {
        position: relative;
        height: 500px;}

    section.dis-bnr h2 {
        position: relative;
        color: #fff;
        margin-top: 100px;}

    section.Fueladvances-bnr h2 {
        margin-top: 105px;
        font-size: 46px;
        line-height: 60px;
    }
    section.Fueladvances-bnr {
        position: relative;
        height: 440px;
    }
    section.Compliance-bnr {
        position: relative;
        height: 500px;}
    section.fulecard-bnr {
        position: relative;
        height: 550px;}

        .headercnt {
         display: none;
        }
        .believe {
           padding: 45px 35px;}
        img.bannertwo {
            top: unset;
            bottom: 3%;
            position: absolute;
            max-width: 600px;
            right: calc(50% - 268px);
            transform: translate(48%, 0);
        }
        p.perfec3t {
           font-size: 23px;
            line-height: 20px;
            padding-top: 8px;}
        h1.bnr-title {
            font-size: 50px;
            line-height: 75px;
            padding-top: 80px;
                }
        section.dispatcher-result {
            padding-top: 70px;
            padding-bottom: 70px;
            position: relative;
        }
        section.Newserivces {
            position: relative;
            padding-top: 80px;
            padding-bottom: 60px;
        }
        section.howworks h2.How {
            font-weight: 400;
            font-size: 65px;
            line-height: 110px;}
        
        .media-body p br {
            display: none;
        }

    .why-choose-block .inner-box .icon h4 {
       margin-top: 27px;
       font-size: 18px;
       line-height: 28px;
        }

    .why-choose-block .inner-box {
       padding: 25px 15px 20px;}

    section.Newserivces .react-tabs__tab {
       padding-left: 25px;
       width: 270px !important;
    }  

       .work-block .content-box {
        position: relative;
        padding-top: 16px;
        width: 100%;
    }
    section.Newserivces .inner-column {
            position: relative;
            
        }
        .work-block .inner-box {
          display: block;
        }
        .work-block .content-box .icon-box {
            display: none;}
        .work-block .image-box .image {
            height: 180px;
            width: 180px;
           margin-right: 0px;
        }
        .contactdetails {
            padding: 30px 40px 20px 40px;
        }
        .media-body p  {
            font-size: 16px;
            }
    section.Aboutus-bnr {
       height: 550px;}
       section.abt-insurance .about-inner-wrap {
        margin-left: 25px;
    }
    section.Fuelcardbenefits-tab .react-tabs__tab {
        height: 145px;
        padding-top: 5px;
        list-style: none;
        line-height: 32px;
        margin-right: 36px;
        position: relative;
        font-size: 20px;
        width: 191px;
        margin-bottom: 32px;
        padding-left: 30px;
    }
    section.Fuelcardbenefits-tab .icon-box-style8 {
        position: relative;
        background: #fff;
        padding: 40px 35px;
    }
    section.Fuelcardbenefits-tab {
        padding-top: 60px;
        padding-bottom: 40px;
    }
    h2.does {
        font-weight: 400;
        font-size: 89px;
        line-height: 102px;}
    section.serivces-home {
        padding-top: 45px;
        padding-bottom: 50px;
    }
    img.sidedispatch {
        position: absolute;
        bottom: 0;
        width: 50%;
        right: 0;
    }
    .bnerouter {
        position: relative;
        left: 3%;
        top: 25%;
    }
    h1.video-title {
        color: #fff;
        letter-spacing: 1px;
        font-size: 60px;
        line-height: 70px;
        font-family: "Onest", sans-serif;
    }
    .work svg {
        position: absolute;
        top: 45%;
        right: 0;
        height: 70px;
        transform: rotate(135deg);
    }
    .work {
        padding-top: 54px;
        position: relative;
      margin-bottom: 30px;

    }
    .whay-choose-block .icon:before {
        position: absolute;
        content: "";
        left: 10px;
        top: 40px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        opacity: 0.4;
    }
    section.abt-feature .whay-choose-block {
     padding-right: 10px;
    }
   
    h2.How {
        font-weight: 400;
        font-size: 95px;
        line-height: 105px;
    }
    section.howworks {
        padding-top: 0px;
        padding-bottom: 50px;
        position: relative;
    }
    section.Profit-cal {
        padding-bottom: 80px;
        padding-top: 60px;
    }
    .newbox {
        padding: 40px 70px 40px 20px;
    }
    section#ddd h2 {
        color: #fff;
        font-size: 32px;
        margin-top: 12px;
    }
}
@media (max-width: 1100px) and (min-width: 1024px){
    .bnerouter {
        position: relative;
        left: 3%;
        top: 25%;
    }
    section.insu-bnr h2 {
        position: relative;
        color: #fff;
        margin-top: 85px;}
    section.insu-bnr {
        position: relative;
        height: 510px;}

    section.dis-bnr {
        position: relative;
        height: 500px;}

    section.Fueladvances-bnr h2 {
        margin-top: 100px;
        font-size: 46px;
        line-height: 60px;
    }
    section.Fueladvances-bnr {
        position: relative;
        height: 440px;
    }
    section.Compliance-bnr .dispatch-content h2 {
        margin-top: 85px;
        font-size: 46px;
        line-height: 60px;
    }
    section.Compliance-bnr {
        position: relative;
        height: 440px;
    }
    .dispatch-content h2 {
        position: relative;
        color: #fff;
        margin-top: 100px;}
    section.abour-counter {
        padding-top: 70px;
        padding-bottom: 70px;}
    .headercnt {
        display: none;
    }
    section.abt-feature .whay-choose-block {
      padding-left: 20px;
        padding-top: 50px;
        padding-right: 10px;
    }
    .phonecall {
        left: -27px;
    }
    section.dispatcher-result {
        padding-top: 70px;
          }
    section.Newserivces .react-tabs__tab {
        padding: 30px 25px 14px 25px;
        padding-left: 30px;
        font-size: 20px;
        line-height: 22px;
        width: 265px !important;
    }
    h1.bnr-title {
        font-size: 50px;
        line-height: 70px;
        padding-top: 80px;
         }
         p.perfec3t {
            font-size: 22px;
           padding-bottom: 20px;
            padding-top: 0px;
              }
              img.bannertwo {
                top: unset;
                bottom: 1%;
                position: absolute;
                max-width: 700px;
                right: calc(50% - 359px);
                transform: translate(50%, 0);
            }
    .bnr-background{
        padding-right: 160px;
    }
    section.myheader li.nav-item {
        padding: 10px 10px;}
    .believe {
        padding: 45px 30px;}
    .work-block .content-box .icon-box .count{
        display: none;
    }
    .work-block .inner-box {
        position: relative;
        background-color: #f5f5f5;
        padding: 80px 90px 65px;
        padding-right: 75px;
        display: flex;
    }
    section.Newserivces .inner-column {
        position: relative;
    }
    .work-block .image-box .image {
        position: relative;
        height: 200px;
        width: 200px;
        margin-right: 35px;}

    section.howworks h2.How {
        font-weight: 400;
        font-size: 65px;
        line-height: 110px;}

    .why-choose-block .inner-box .icon h4 {
        margin-top: 27px;
        font-size: 18px;
        line-height: 28px;
         }
 
     .why-choose-block .inner-box {
        padding: 25px 15px 20px;}
    .media-body p br {
        display: none;
    }
    .contactdetails {
        padding: 30px 40px 20px 40px;
    }
    .media-body p  {
        font-size: 16px;
        }
    .about-inner-wrap {
        margin-left: 0px;
    }
    section.Fuelcardbenefits-tab .react-tabs__tab {
        height: 145px;
          padding-top: 5px;
          list-style: none;
          line-height: 34px;
          margin-right: 36px;
          position: relative;
          font-size: 20px;
          width: 230px;
          margin-bottom: 32px;
          padding-left: 30px;
      }
      section.Fuelcardbenefits-tab .icon-box-style8 {
          position: relative;
          background: #fff;
          padding: 40px 35px;
      }
      section.Fuelcardbenefits-tab {
          padding-top: 60px;
          padding-bottom: 40px;
      }
    section.fulecard-bnr {
        height: 570px;
    }

    section#ddd h2 {
        color: #fff;
        font-size: 32px;
        margin-top: 12px;
    }
    h2.does {
        font-weight: 400;
        font-size: 60px;
        line-height: 89px;}
    section.Aboutus-bnr {
     height: 470px;
           }
    img.smallreffer {
        margin: 0 auto;
        display: block;
        width: 100px;
    }
      .whay-choose-block .icon:before {
        position: absolute;
        content: "";
        left: 10px;
        top: 40px;
        width: 70px;
        height: 72px;
        border-radius: 50%;
        opacity: 0.4;
    }
h3.totalresult {
    color: #fff;
    font-size: 26px;}
    .resultchart {
        border: 10px solid #6e6c6c;
        border-radius: 50%;
        height: 344px;
        margin: 0 auto;
        padding: 46px 20px 25px;
        width: 360px;
    }
    .work {
        padding-top: 54px;
        position: relative;
        margin-bottom: 30px;
    }
    .work svg {
        position: absolute;
        top: 45%;
        right: 0;
        height: 75px;
        transform: rotate(135deg);
    }
    section.howworks {
        padding-top: 0px;
        padding-bottom: 50px;
        position: relative;
    }
    section.Profit-cal {
        padding-bottom: 80px;
        padding-top: 60px;
    }
    .newbox {
        padding: 40px 70px 40px 20px;
    }
    img.sidedispatch {
        position: absolute;
        bottom: 0;
        width: 50%;
        right: 0;
    }
    h1.video-title {
        color: #fff;
        letter-spacing: 1px;
        font-size: 64px;
        line-height: 80px;
        font-family: "Onest", sans-serif;
    }
}
@media (max-width: 1199px) and (min-width: 1101px){
    
    .bnerouter {
        position: relative;
        left: 3%;
        top: 25%;
    }
    section.dis-bnr {
        position: relative;
        height: 480px;}
    section.dis-bnr h2 {
        position: relative;
        color: #fff;
        margin-top: 100px;}
    section.Fueladvances-bnr h2 {
        margin-top: 100px;
        font-size: 46px;
        line-height: 60px;
    }
    section.Fueladvances-bnr{
        position: relative;
        height: 440px;
    }
    section.Compliance-bnr {
        position: relative;
        height: 440px;
    }
    section.Compliance-bnr .dispatch-content h2 {
        margin-top: 85px;
        font-size: 46px;
        line-height: 60px;
    }
    section.Compliance-bnr {
        position: relative;
        height: 440px;
    }
    section.Fuelcardbenefits-tab {
        padding-top: 80px;
        padding-bottom: 60px;
    }
    p.established3 {
        padding-bottom: 60px;}
    section.Fuelcardbenefits-tab .react-tabs__tab {
        height: 145px;
        padding-top: 5px;
        list-style: none;
        line-height: 32px;
        margin-right: 36px;
        position: relative;
        font-size: 20px;
        width: 191px;
        margin-bottom: 32px;
        padding-left: 30px;
    }
    section.abt-feature .whay-choose-block {
        padding-left: 20px;
        padding-top: 50px;
        padding-right: 10px;
    }
    .believe {
        padding: 45px 36px;}

    section.howworks h2.How {
        font-weight: 400;
        font-size: 68px;}
    section.Newserivces .react-tabs__tab {
        padding: 30px 25px 14px 25px;
        padding-left: 30px;
        font-size: 20px;
        line-height: 22px;
        width: 275px !important;
    }
    .work-block .image-box .image {
        position: relative;
        height: 220px;
        width: 220px;
        margin-right: 30px;
    }
    section.mybnr {
        position: relative;
        overflow: hidden;
        height: 620px;}
    .bnr-background {
        padding-right: 350px;
    }
    img.bannertwo {
        top: 20%;
        max-width: 650px;
        right: calc(50% - 467px);
       }

h1.bnr-title {
    font-size: 46px;
    line-height: 67px;
     padding-top: 72px;
  }
    img.smallreffer {
        margin: 0 auto;
        display: block;
        width: 100px;
    }
   
    .whay-choose-block .icon:before {
        position: absolute;
        content: "";
        left: 10px;
        top: 40px;
        width: 70px;
        height: 72px;
        border-radius: 50%;
        opacity: 0.4;
    }
h3.totalresult {
    color: #fff;
    font-size: 26px;}
    .resultchart {
        border: 10px solid #717070;
        border-radius: 50%;
        height: 344px;
        margin: 0 auto;
        padding: 46px 20px 25px;
        width: 360px;
    }
    .work {
        padding-top: 54px;
        position: relative;
        margin-bottom: 30px;
    }
    .work svg {
        position: absolute;
        top: 45%;
        right: 0;
        height: 75px;
        transform: rotate(135deg);
    }
    section.howworks {
        padding-top: 0px;
        padding-bottom: 50px;
        position: relative;
    }
    section.Profit-cal {
        padding-bottom: 80px;
        padding-top: 60px;
    }
    .newbox {
        padding: 40px 70px 40px 20px;
    }
    img.sidedispatch {
        position: absolute;
        bottom: 0;
        width: 50%;
        right: 0;
    }
    h1.video-title {
        color: #fff;
        letter-spacing: 1px;
        font-size: 64px;
        line-height: 80px;
        font-family: "Onest", sans-serif;
    }
}
@media (max-width: 1240px) and (min-width: 1200px){
    br.huge {
        display: none;
    }
    section.dis-bnr {
        position: relative;
        height: 480px;}
    section.dis-bnr h2 {
        position: relative;
        color: #fff;
        margin-top: 100px;}
    section.Compliance-bnr {
        position: relative;
        height: 440px;
    }
    section.Fueladvances-bnr {
        position: relative;
        height: 440px;
    }
    section.Fueladvances-bnr h2 {
        margin-top: 85px;
        font-size: 46px;
        line-height: 60px;
    }
    section.Compliance-bnr .dispatch-content h2 {
        margin-top: 85px;
        font-size: 46px;
        line-height: 60px;
    }
    section.Compliance-bnr {
        position: relative;
        height: 440px;
    }
    .dispatch-content h2 {
        position: relative;
        color: #fff;
        margin-top: 100px;}
    section.myheader li.nav-item {
        padding: 10px 15px;}
    .bluefuelcard h4 {
        font-size: 22px;
        margin-bottom: 15px;
    }
    br.operors {
        display: none;
    }
    br.sbility {
        display: none;
    }
    section.Fuelcardbenefits-tab .react-tabs__tab {
        width: 247px;
        margin-right: 26px;
        }
        .whay-choose-block .inner-box {
            position: relative;
            margin-bottom: 30px;
            min-height: 120px;
            padding-left: 20px;
            padding-right: 10px;
        }
        .whay-choose-block .icon {
          padding-left: 20px;
        }
        p.content-serivce {
            padding-bottom: 22px;
            font-size: 20px;
            margin-top: 20px;
            padding-top: 0px;
        }
        section.Newserivces .react-tabs__tab {
            padding: 30px 25px 14px 25px;
            padding-left: 30px;
            font-size: 20px;
            line-height: 22px;
            width: 320px !important;
        }
        .counter.box {
            margin-top: 0px;
        }
        .injured h2 {
            font-size: 42px;
            color: #fff;
            line-height: 72px;
        }
        .injured {
            margin-left: 3%;
            padding: 80px 30px 80px 80px;}
        section.mybnr::after {
         bottom: -333px;
        }
        section.mybnr {
           height: 680px;
              }
        img.bannertwo {
            top: 20%;
            position: absolute;
            max-width: 750px;
            right: calc(50% - 500px);}
        h1.bnr-title {
            font-size: 50px;
            line-height: 68px;
             padding-top: 75px;
             }
        .bnr-background {
            padding-right: 380px;
        }
        .work-block .image-box .image {
            height: 250px;
            width: 250px;
            margin-right: 40px;
        }
        .facts {
            height: 340px;
        }
        .work-block .inner-box {
            padding: 85px 70px 85px;
            padding-right: 75px;
                }
        .contactdetails {
            padding: 30px 40px 20px 40px;
        }
        section.fulecard-bnr {
            height: 580px;
        }
        .about-inner-wrap {
            margin-left: 0px;
        }
    section.Aboutus-bnr {
        position: relative;
        height: 470px;}
        h2.does {
            font-weight: 400;
            font-size: 64px;
            line-height: 90px;
            margin: 0;
        }
    .navbar-area-1 .nav-container .navbar-collapse .navbar-nav {
        padding-left: 0px;
    }
    .bnerouter {
        position: relative;
        left: 3%;
        top: 25%;
    }
    .facts h4 {
        font-size: 22px;
    }
    section.abt-feature .whay-choose-block {
        border: 1px solid rgba(0, 0, 0, 0.0509803922);
        padding: 0;
        padding-left: 0;
        padding-top: 50px;
    }
    h2.How {
        font-weight: 400;
        font-size: 106px;
        line-height: 125px;
        margin: 0;
        -webkit-text-stroke: 1px #000;
        background: transparent;
        color: transparent;
        font-family: "Russo One", sans-serif;
    }
    section.Profit-cal {
        padding-bottom: 80px;
        padding-top: 50px;
    }
    .newbox h4 {
        margin-bottom: 17px;
        font-size: 26px;
    }
    img.sidedispatch {
        position: absolute;
        bottom: 0;
        width: 100%;
        right: 0;
    }
    h1.video-title {
        color: #fff;
        letter-spacing: 1px;
        font-size: 70px;
        line-height: 80px;
        font-family: "Onest", sans-serif;
    }
}
@media (max-width: 1399px) and (min-width: 1241px){
    .navbar-area-1 .nav-container .navbar-collapse .navbar-nav {
        padding-left: 0px;
    }
    section.Fueladvances-bnr {
        position: relative;
        height: 480px;
    }
    ul.agree li {
        font-size: 18px;
        line-height: 28px;
    }
    ul.agree li {
        font-size: 18px;
        line-height: 28px;
    }
    section.Fueladvances-bnr h2 {
        margin-top: 85px;
        font-size: 46px;
        line-height: 60px;
    }
    section.Compliance-bnr .dispatch-content h2 {
        margin-top: 100px;
        font-size: 48px;
        line-height: 60px;
    }
    section.Compliance-bnr {
        position: relative;
        height: 480px;}
    section.dispatcher-result {
        padding-top: 70px;}
    p.content-serivce {
        padding-bottom: 22px;
        font-size: 20px;
        margin-top: 20px;
        padding-top: 0px;
    }
    section.Newserivces .react-tabs__tab {
        padding: 30px 25px 14px 25px;
        padding-left: 30px;
        font-size: 20px;
        line-height: 22px;
        width: 320px !important;
    }
    .injured h2 {
        font-size: 42px;
        color: #fff;
        line-height: 72px;
    }
    .injured {
        margin-left: 3%;
        padding: 80px 30px 80px 80px;}
    section.mybnr::after {
     bottom: -333px;
    }
    section.mybnr {
       height: 680px;
          }
    img.bannertwo {
        top: 20%;
        position: absolute;
        max-width: 750px;
        right: calc(50% - 500px);}
    h1.bnr-title {
        font-size: 50px;
        line-height: 68px;
         padding-top: 75px;
         }
    .bnr-background {
        padding-right: 380px;
    }
    .work-block .image-box .image {
        height: 250px;
        width: 250px;
        margin-right: 40px;
    }
    .facts {
        height: 340px;
    }
    .work-block .inner-box {
        padding: 85px 70px 85px;
        padding-right: 75px;
            }

    .contactdetails {
        padding: 30px 40px 20px 40px;
    }
    section.Aboutus-bnr {
        height: 580px;
    }
    section.fulecard-bnr {
        height: 580px;
    }
    section.Fuelcardbenefits-tab .react-tabs__tab {
       width: 218px;
      margin-bottom: 32px;
    }

}
@media (max-width: 1440px) and (min-width: 1400px){
    .bnerouter {
        position: relative;
        left: 4%;
        top: 25%;
    }
    ul.agree li {
        font-size: 18px;
        line-height: 28px;
    }
    section.fulecard-bnr {
        position: relative;
        height: 560px;
        overflow: hidden;
        background-size: cover;
        background-position: center;
    }
    section.Fueladvances-bnr {
        position: relative;
        height: 500px;
    }
    
    section.Fueladvances-bnr h2 {
        margin-top: 100px;
        font-size: 46px;
        line-height: 60px;
    }
    section.Compliance-bnr .dispatch-content h2 {
        margin-top: 100px;
        font-size: 48px;
        line-height: 60px;
    }
    section.Compliance-bnr {
        position: relative;
        height: 540px;}
    br.save {
        display: none;
    }
    br.operors {
        display: none;
    }
    br.sbility {
        display: none;
    }
    section.Aboutus-bnr {
        height: 515px;
    }
    br.huge {
        display: none;
    }
    section.mybnr::after {
      bottom: -323px;
    }
    section.mybnr {
       height: 670px;}

    img.bannertwo {
        top: 20%;
        position: absolute;
        max-width: 800px;
        right: calc(50% - 450px);
        transform: translate(50%, 0);
    }
    h1.bnr-title {
        padding-top: 100px;
    }
    .dispatch-content h2 {
     margin-top: 115px;}

     .single-fact-wrap {
        margin-top: 16px;
        }
    h1.video-title {
        color: #fff;
        letter-spacing: 1px;
        font-size: 70px;
        line-height: 82px;
        font-family: "Onest", sans-serif;
    }
}
@media (max-width: 1600px) and (min-width: 1441px){
    br.huge {
        display: none;
    }
    ul.agree li {
        font-size: 18px;
        line-height: 28px;
    }
    br.save {
        display: none;
    }
    section.Compliance-bnr .dispatch-content h2 {
        margin-top: 100px;
        font-size: 48px;
        line-height: 60px;
    }
    section.Compliance-bnr {
        position: relative;
        height: 530px;}
    br.sbility {
        display: none;
    }
    img.nlslogo {
        margin-right: 35px;
    }
}
@media (max-width: 1920px) and (min-width: 1601px){
    br.huge {
        display: none;
    }
    ul.agree li {
        font-size: 18px;
        line-height: 28px;
    }
    br.save {
        display: none;
    }
    br.sbility {
        display: none;
    }
    br.operors {
        display: none;
    }
    img.nlslogo {
        margin-right: 35px;
    }
}